import React, { useCallback, useEffect, createContext, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Backdrop, CircularProgress } from '@mui/material';
import QuoteCreateForm from './QuoteCreateForm';

const { PRIVATE_ROUTE } = API_ROUTES;
function QuoteForm() {
  const { authAxios } = useContext(FetchContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(true);
  const [costItemData, setcostItemData] = useState([]);
  const [categoryData, setcategoryData] = useState([]);
  const [clientData, setclientData] = useState([]);
  const [ratesheetData, setratesheetData] = useState([]);
  const [joballData, setjoballData] = useState([]);
  const [managerData, setmanagerData] = useState([]);
  const [multivalue, setmultivalue] = React.useState([]);
  const [labourCostData, setLabourCostData] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    control,
    watch,
  } = useForm(
    !id
      ? {
          defaultValues: {
            text_exempt: false,
            attachment: '',
            remark: '',
            project_manager: '',
          },
        }
      : {
          defaultValues: {
            active: false,
            text_exempt: false,
            attachment: '',
            remark: '',
            project_manager: '',
            Client: '',
            RateSheet: '',
            client_id: '',
            job_id: '',
            ratesheet_id: '',
          },
        }
  );
  const getRatesheetFormById = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_QUOTE_BY_ID}/${id}`);
    console.log('data_____', data);

    setmultivalue(data.data[0]?.project_manager);
    setEditData(data.data[0]);
    setValue('quote_number', data?.data[0]?.quote_number);
    setValue('Client', data?.data[0]?.client_id?.company_name);
    setValue('job_id', data?.data[0]?.job_id?._id);
    setValue('job', data?.data[0]?.job_number);
    // setValue('site_location', data?.data[0]?.site_location);
    setValue('client_id', data?.data[0]?.client_id?._id);
    setValue('RateSheet', data?.data[0]?.ratesheet_id?.name);
    setValue('ratesheet_id', data?.data[0]?.ratesheet_id?._id);
    setValue('total_cost_hour', data?.data[0]?.total_cost_hour);
    setValue('total_estimated_hour', data?.data[0]?.total_estimated_hour);
    setValue('name', data?.data[0]?.name);
    setValue('active', data?.data[0]?.active);
    setValue('remark', data?.data[0]?.remark);
    setValue('attachment', data?.data[0].attachment);
    setValue('description', data?.data[0]?.description);
    setValue('project_manager', data?.data[0]?.project_manager);
    data?.data[0]?.billable_line_items?.labourItem?.map((element, i) => {
      setValue(`labourItem.${i}.costItem`, element?.costItem);
      setValue(`labourItem.${i}.name`, element?.name);
      setValue(`labourItem.${i}.unit`, element?.unit);
      setValue(`labourItem.${i}.unitCost`, element?.unitCost);
      setValue(`labourItem.${i}.hourlyCost`, element?.hourlyCost);
      setValue(`labourItem.${i}.estimated_hour`, element?.estimated_hour);
      setValue(`labourItem.${i}.text_exempt`, element?.text_exempt);
    });
    data?.data[0]?.billable_line_items?.materialItem?.map((element, i) => {
      setValue(`materialItem.${i}.costItem`, element?.costItem);
      setValue(`materialItem.${i}.name`, element?.name);
      setValue(`materialItem.${i}.unit`, element?.unit);
      setValue(`materialItem.${i}.unitCost`, element?.unitCost);
      setValue(`materialItem.${i}.text_exempt`, element?.text_exempt);
    });
    data?.data[0].billable_line_items?.fixItem?.map((element, i) => {
      setValue(`fixItem.${i}.costItem`, element?.costItem);
      setValue(`fixItem.${i}.name`, element?.name);
      setValue(`fixItem.${i}.unit`, element?.unit);
      setValue(`fixItem.${i}.unitCost`, element?.unitCost);
      setValue(`fixItem.${i}.text_exempt`, element?.text_exempt);
    });
    setLoading(false);
  }, [authAxios, id, setValue]);

  const getclientdata = useCallback(async () => {
    const { data } = await authAxios.get(`${PRIVATE_ROUTE.GET_COSTITEAMALL}`);
    const categorydata = await authAxios.get(`${PRIVATE_ROUTE.GET_CATEGORY}`);
    const jobdata = await authAxios.get(`${PRIVATE_ROUTE.GET_JOBALL}`);

    const clientdata = await authAxios.get(`${PRIVATE_ROUTE.GET_CLIENTALL}`);
    const ratesheetdata = await authAxios.get(`${PRIVATE_ROUTE.GET_RATESHEETALL}`);
    const managerdata = await authAxios.get(`${PRIVATE_ROUTE.GET_MANAGER}`);
    setcostItemData(data.data);
    setcategoryData(categorydata.data.data);
    setclientData(clientdata.data.data);
    setjoballData(jobdata.data.data);
    setratesheetData(ratesheetdata.data.data);
    setmanagerData(managerdata.data.data);

    if (id) {
      getRatesheetFormById();
    }

    setLoading(false);
  }, [authAxios, id]);

  useEffect(() => {
    getclientdata();
  }, [getclientdata, id]);

  const onSubmit = async (data) => {
    const newdata = {
      ...data,
      client_id: data?.client_id?.trim(),
      job_id: data?.job_id?.trim(),
      ratesheet_id: data?.ratesheet_id?.trim(),
    };
    if (id) {
      const response = await authAxios.post(`${PRIVATE_ROUTE?.EDIT_QUOTE}${id}`, newdata);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/quote/list');
      } else {
        toast.error(response.data.massage);
        setLoading(false);
      }
    } else {
      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_QUOTE, newdata);
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/quote/list');
        setLoading(false);
      } else {
        toast.error(response.data.massage);
        setLoading(false);
      }
    }
    editData?.billable_line_items?.labourItem?.map((element, i) => {
      setValue(`labourItem.${i}.costItem`, '');
      setValue(`labourItem.${i}.name`, '');
      setValue(`labourItem.${i}.unit`, '');
      setValue(`labourItem.${i}.unitCost`, '');
      setValue(`labourItem.${i}.hourlyCost`, '');
      setValue(`labourItem.${i}.estimated_hour`, '');
      setValue(`labourItem.${i}.text_exempt`, '');
    });
    editData?.billable_line_items?.materialItem?.map((element, i) => {
      setValue(`materialItem.${i}.costItem`, '');
      setValue(`materialItem.${i}.name`, '');
      setValue(`materialItem.${i}.unit`, '');
      setValue(`materialItem.${i}.unitCost`, '');
      setValue(`materialItem.${i}.text_exempt`, '');
    });
    editData?.billable_line_items?.fixItem?.map((element, i) => {
      setValue(`fixItem.${i}.costItem`, '');
      setValue(`fixItem.${i}.name`, '');
      setValue(`fixItem.${i}.unit`, '');
      setValue(`fixItem.${i}.unitCost`, '');
      setValue(`fixItem.${i}.text_exempt`, '');
    });
    setValue('name', '');
    setValue('active', '');
    setValue('remark', '');
  };
  return (
    <>
      {id
        ? loading && (
            <div>
              <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )
        : ''}

      <QuoteCreateForm
        setLabourCostData={setLabourCostData}
        register={register}
        watch={watch}
        editData={editData}
        getValues={getValues}
        categoryData={categoryData}
        clientData={clientData}
        ratesheetData={ratesheetData}
        managerData={managerData}
        joballData={joballData}
        costItemData={costItemData}
        handleSubmit={handleSubmit}
        setValue={setValue}
        errors={errors}
        onSubmit={onSubmit}
        params={id}
        loading={isSubmitting}
        isEdit={id ? 'true' : 'false'}
        control={control}
        multivalue={multivalue}
        setmultivalue={setmultivalue}
        labourCostData={labourCostData}
      />
    </>
  );
}

export default QuoteForm;
