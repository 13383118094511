import * as React from 'react';
import { useContext } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  useAutocomplete,
  TextareaAutosize,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  Chip,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Controller, get, useFieldArray } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import CancelIcon from '@mui/icons-material/Cancel';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';
import { CheckBox } from '@mui/icons-material';
import { FormElement } from '@progress/kendo-react-form';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { createTheme } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function ManagersideQuoteCreateForm({
  register,
  errors,
  setValue,
  categoryData,
  costItemData,
  managerData,
  ratesheetData,
  clientData,
  isEdit,
  getValues,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control,
  editData,
  setmultivalue,
  multivalue,
  joballData,
  watch,
  setLabourCostData,
  labourCostData,
}) {
  console.log("labourCostData---->", labourCostData);

  const filterdata = costItemData
    ?.filter((item) => {
      return item.category === categoryData[0]?.name;
    })
    .map((item) => {
      return { label: item.name, id: item._id };
    });

  const navigate = useNavigate();
  const [textAreaCount, settextAreaCount] = React.useState(0);
  const [checkbox, setcheckbox] = React.useState();
  const [labourcheckbox, setLabourcheckbox] = React.useState();
  const [materialcheckbox, setMaterialcheckbox] = React.useState();
  const [fixedcheckbox, setfixedcheckbox] = React.useState();
  const [tabValue, setTabValue] = React.useState(0);
  const [attachmentsData, setattachmentsData] = React.useState([]);
  const [isValidate, setIsValidate] = React.useState(true);
  const [unitcost, setunitcost] = React.useState([]);
  const [estimatedhour, setestimatedhour] = React.useState([]);
  const [personName, setPersonName] = React.useState('');
  const [jobData, setjobData] = React.useState([]);
  const [selectRateStreet, setSelectRateStreet] = React.useState('1')


  React.useEffect(() => {
    if (params) {
      setValue('total_cost_hour', editData?.total_cost_hour);
      setValue('total_estimated_hour', editData?.total_estimated_hour);
    }
  }, [setValue]);

  const handleFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setValue(`attachment`, reader.result);
      setattachmentsData({ ...attachmentsData, attachmentsData: reader.result });
    };
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const {
    fields: labourItem,
    remove: removeLabour,
    insert: insertLabour,
  } = useFieldArray({
    control,
    name: 'labourItem',
  });
  const {
    fields: materialItem,
    remove: removeMaterial,
    insert: insertMaterial,
  } = useFieldArray({
    control,
    name: 'materialItem',
  });
  const {
    fields: fixItem,
    remove: removeFix,
    insert: insertFix,
  } = useFieldArray({
    control,
    name: 'fixItem',
  });

  React.useEffect(() => {

    if (selectRateStreet === 'SelectRateStreet') {
      console.log("SelectRateStreet--->", selectRateStreet);
      removeLabour();
      removeFix()
      removeMaterial()

      if (labourCostData?.labourItem?.length) {
        // removeMaterial([])
        let esthrarr = [];
        let costarr = [];
        labourCostData?.labourItem?.forEach((item, index) => {
          esthrarr.push(1);
          costarr.push(item.unitCost);
          insertLabour(index, {
            costItem: item.costItem || '',
            name: item.name || '',
            unit: item.unit || '',
            unitCost: item.unitCost || '',
            hourlyCost: item.hourlyCost || '',
            estimated_hour: 1,
            text_exempt: item.text_exempt || false,
          });
        });
        setestimatedhour(esthrarr);
        setunitcost(costarr);
      }
      if (labourCostData?.fixItem?.length) {
        // removeFix()
        // removeMaterial([])
        labourCostData?.fixItem?.forEach((item, index) => {
          insertFix(index, {
            costItem: item.costItem || '',
            name: item.name || '',
            unit: item.unit || '',
            unitCost: item.unitCost || '',
            text_exempt: item.text_exempt || false,
          });
        });
      }
      if (labourCostData?.materialItem?.length) {
        // removeMaterial()
        // removeMaterial([])
        labourCostData?.materialItem?.forEach((item, index) => {

          console.log("item-->", item);

          insertMaterial(index, {
            costItem: item.costItem || '',
            name: item.name || '',
            unit: item.unit || '',
            unitCost: item.unitCost || '',
            text_exempt: item.text_exempt || false,
          });
        });
      }
    }
  }, [labourCostData, insertLabour, insertFix, insertMaterial]);


  const handleLabourDelete = (i) => {
    console.log("deleteItem--->",i);
    
    removeLabour(i, 1);
    const updatedItems = [...estimatedhour];
    updatedItems.splice(i, 1);
    const updatedCosts = [...unitcost];
    updatedCosts.splice(i, 1);
    setunitcost(updatedCosts);
    setestimatedhour(updatedItems);
  };
  const handleMaterialDelete = (i) => {
    removeMaterial(i, 1);
  };
  const handleFixDelete = (i) => {
    removeFix(i, 1);
  };
  const handleAddLabour = () => {
    insertLabour(labourItem?.length, {
      costItem: '',
      name: '',
      unit: '',
      unitCost: '',
      hourlyCost: '',
      estimated_hour: '',
      text_exempt: false,
    });
  };
  const handleAddMaterial = () => {
    insertMaterial(materialItem?.length, {
      costItem: '',
      name: '',
      unit: '',
      unitCost: '',
      text_exempt: false,
    });
  };
  const handleAddFix = () => {
    insertFix(fixItem?.length, {
      costItem: '',
      name: '',
      unit: '',
      unitCost: '',
      text_exempt: false,
    });
  };

  let editlabourcheckbox;
  editData?.billable_line_items?.labourItem?.map((element, i) => {
    editlabourcheckbox = getValues(`labourItem.${i}.text_exempt`);
  });

  React.useEffect(() => {
    setLabourcheckbox(editlabourcheckbox || null);
  }, [editlabourcheckbox]);

  React.useEffect(() => {
    if (params && editData?.billable_line_items?.labourItem?.length !== 0) {
      let esthrarr = [];
      let costarr = [];
      editData?.billable_line_items?.labourItem?.map((element, i) => {
        esthrarr.push(element.estimated_hour);
        costarr.push(element.unitCost);
        handleAddLabour();
        handleLabourDelete(labourItem?.length);
      });
      setestimatedhour(esthrarr);
      setunitcost(costarr);
    }
    if (params && editData?.billable_line_items?.materialItem?.length !== 0) {
      editData?.billable_line_items?.materialItem?.map((element, i) => {
        handleAddMaterial();
        handleMaterialDelete(materialItem?.length);
      });
    }
    if (params && editData?.billable_line_items?.fixItem?.length !== 0) {
      editData?.billable_line_items?.fixItem?.map((element, i) => {
        handleAddFix();
        handleFixDelete(fixItem.length);
      });
    }
    if (editData?.client_id?._id) {
      const clientJobData = joballData
        // ?.filter((item) => item?.client_id === editData?.client_id?._id)
        ?.map((item, index) => {
          return item;
        });
      setjobData(clientJobData);
    }
  }, [editData]);

  const UnitItem = [
    {
      label: 'HR',
      id: 'HR',
    },
    {
      label: 'DAY',
      id: 'DAY',
    },
    {
      label: 'PER',
      id: 'PER',
    },
    {
      label: 'UNIT',
      id: 'UNIT',
    },
  ];

  const calculateTotalHours = (index, newValue) => {
    setestimatedhour((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = newValue;
      console.log(114, newArray);

      return newArray;
    });
  };
  const totalEstimatedHour = estimatedhour.reduce((accumulator, currentValue) => {
    return accumulator + Number(currentValue);
  }, 0);
  if (totalEstimatedHour || params) {
    setValue('total_estimated_hour', totalEstimatedHour);
  }

  const multiplyAndSum = (arr1, arr2) => {
    const multipliedValues = arr1.map((value, index) => {
      const parsedValue1 = value;
      const parsedValue2 = arr2[index] ?? 0;

      return parsedValue1 * parsedValue2;
    });

    const total = multipliedValues.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    setValue('total_cost_hour', total);
    return total;
  };

  const TotalCostHour = multiplyAndSum(unitcost, estimatedhour);

  const calculateTotalCost = (index, newValue) => {
    setunitcost((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = newValue;
      console.log(307, newArray);
      return newArray;
    });
  };

  // client select dropdown
  const getClientData = clientData
    ?.filter((item) => item?.active === true)
    .map((item) => ({ label: item.company_name, id: item._id }));

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: 'red' },
        },
      },
    },
  });

  const handleChangeField = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value?.split(',')[0] : value);
    setValue('Client', value?.split(',')[0]);
    setValue('client_id', value?.split(',')[1].trim());
    setPersonName(getValues('Client'));

    const clientJobData = joballData
      // ?.filter((item) => item?.client_id === getValues('client_id'))
      ?.map((item, index) => {
        return item;
      });

    setjobData(clientJobData);
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  let clientState = 'Client';
  const stateData = clientState.split('.');
  let checked = 'checked';
  let errorMassage = false;
  if (!checked) {
    if (
      errors !== null &&
      errors[`${stateData[0]}`] &&
      errors[`${stateData[0]}`][`${stateData[1]}`] &&
      errors[`${stateData[0]}`][`${stateData[1]}`][`${stateData[2]}`]
    ) {
      errorMassage = true;
    }
  } else if (errors !== null && errors[`${stateData[0]}`]) {
    errorMassage = true;
  }

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} padding={3}>
        {params ? (
          <>
            <Typography variant="h4" gutterBottom>
              Edit Quote
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              Create Quote
            </Typography>
          </>
        )}
        <Grid>
          <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
            {/* <Grid xs={12} item md={6}>
              <FormLabel>
                <>Quote number</>
              </FormLabel>
              <TextField
                fullWidth
                type="number"
                {...register('quote_number', {
                  required: '**Field is required and needs to be a number',
                })}
                onInput={(e) => {
                  e.target.value = e.target.value.toString()?.slice(0, 10);
                }}
                min={0}                 
                error={Boolean(errors.quote_number)}
                helperText={errors.quote_number?.message}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid> */}
            <Grid md={6} xs={12} item>
              <FormLabel>
                <>
                  Client<span style={{ color: 'red' }}>*</span>
                </>
              </FormLabel>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  className={errorMassage ? 'error-input-border' : ''}
                  onChange={(e) => {
                    handleChangeField(e);
                  }}
                  value={getValues('Client') || ''}
                  defaultValue={getValues('Client') || ''}
                  rules={{ required: true }}
                  inputProps={
                    <>
                      <TextField
                        {...register('Client', {
                          required: '**Field is required',
                        })}
                        error={Boolean(errorMassage)}
                        helperText={errorMassage ? '*Field is required' : null}
                      />
                    </>
                  }
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
                        <Chip label={selected?.split(',')[0]} sx={{ backgroundColor: '#65C8D0', color: 'white' }} />
                      </Box>
                    );
                  }}
                >
                  {getClientData === "" || getClientData?.length === 0  &&
                <Box display="flex" alignItems="center" justifyContent="center" marginTop={"3%"}>
                  <CircularProgress color="inherit" size={20} /></Box>}
                  {getClientData?.map((name) => {
                    return (
                      <MenuItem
                        key={name.id}
                        selected={personName === name.label}
                        value={`${name.label},${name.id} `}
                        style={getStyles(name.label, personName, theme)}
                      >
                        {name.label === '' ? 'no data found' : name.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText className="error_msg">{errorMassage ? '**Field is required ' : ''}</FormHelperText>
              </FormControl>
            </Grid>
              <Grid md={6} xs={12} item>
                <FormLabel>
                  <>
                    Select Job
                    {/* <span style={{ color: 'red' }}>*</span> */}
                  </>
                </FormLabel>
                <ChipDropdown
                  state={'job'}
                  secondstate={'job_id'}
                  setValue={setValue}
                  getValues={getValues}
                  data={joballData?.map((item) => {
                    return { label: item?.job_number, id: item._id };
                  })}
                  register={register}
                  validation={false}
                  errors={errors}
                  checked={'checked'}
                />
              </Grid>
           
            <Grid md={6} item xs={12}>
              <FormLabel>
                <>
                  RateSheet<span style={{ color: 'red' }}>*</span>
                </>
              </FormLabel>

              <ChipDropdown
                state={'RateSheet'}
                secondstate={'ratesheet_id'}
                setValue={setValue}
                getValues={getValues}
                data={ratesheetData?.map((item) => {
                  return { label: item?.name, id: item?._id };
                })}
                register={register}
                validation={true}
                errors={errors}
                checked={'checked'}
                rateSheetId="getRateSheetId"
                setLabourCostData={setLabourCostData}
                setSelectRateStreet={setSelectRateStreet}
                selectRateStreet={selectRateStreet}
              />
            </Grid>

            {/* <Grid xs={12} item md={6}>
              <FormLabel>
                <>
                  Name<span style={{ color: 'red' }}>*</span>
                </>
              </FormLabel>
              <TextField
                fullWidth
                type="text"
                {...register('name')}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid> */}

            <Grid xs={12} item md={6}>
              <FormLabel>
                <>Description</>
              </FormLabel>
              <TextField
                {...register('description')}
                fullWidth
                onChange={(e, val) => {
                  settextAreaCount(e.target.value.length);
                  setValue('description', e.target.value);
                }}
                inputProps={{ maxLength: 50 }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography variant="h6" component="h2">
          Billing Line Items
        </Typography>

        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Labour Costs" {...a11yProps(0)} />
              <Tab label="Equipment and Materials" {...a11yProps(1)} />
              <Tab label="Fixed Price" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
              Labour Costs
            </Typography>
            {labourItem?.map((item, i) => (
              <>
                <Grid key={i}>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ marginRight: '10px' }}>
                      <CancelIcon
                        key={i}
                        onClick={() => handleLabourDelete(i)}
                        sx={{ fontSize: '42px', color: 'red' }}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        {''}
                      </CancelIcon>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid sx={{ marginLeft: '20px' }} direction={'row'} container spacing={5}>
                      <Grid container direction={'row'} mt={4} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Cost Item <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`labourItem.${i}.costItem`}
                            setValue={setValue}
                            data={filterdata}
                            validation={true}
                            register={register}
                            errors={errors}
                          />
                        </Grid>
                        {/* <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Name<span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="text"
                            {...register(`labourItem.${i}.name`, {
                              required: '**Field is required and needs to be a text',
                            })}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.labourItem ? errors.labourItem[i]?.name : null)}
                            helperText={
                              Boolean(errors.labourItem ? errors.labourItem[i]?.name : false)
                                ? '*Field is required and needs to be a text'
                                : null
                            }
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid> */}
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit<span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`labourItem.${i}.unit`}
                            setValue={setValue}
                            data={UnitItem}
                            register={register}
                            validation={true}
                            errors={errors}
                            control={control}
                          />
                        </Grid>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit Cost<span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              fullWidth
                              type="number"
                              step="0.01"
                              {...register(`labourItem.${i}.unitCost`, {
                                required: '**Field is required and needs to be a number',
                                pattern: {
                                  value: /^[0-9]/,
                                  message: 'invalid input type',
                                },
                              })}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e, val) => {
                                calculateTotalCost(i, e.target.value);
                              }}
                              min={0}
                              error={Boolean(errors.labourItem ? errors.labourItem[i]?.unitCost : null)}
                              helperText={errors.labourItem ? errors.labourItem[i]?.unitCost?.message : null}
                            />
                          </FormControl>
                        </Grid>
                        {/* <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>Hourly Cost</>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="number"
                            step="0.01"
                            {...register(`labourItem.${i}.hourlyCost`, {
                              pattern: {
                                value: /^[0-9]/,
                                message: 'invalid input type',
                              },
                            })}
                            min={0}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.labourItem ? errors.labourItem[i]?.hourlyCost : null)}
                            helperText={errors.labourItem ? errors.labourItem[i]?.hourlyCost?.message : null}
                          />
                        </Grid> */}
                        <Grid md={6} item xs={12}>
                          <FormLabel>
                            <>
                              Estimated Hour<span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="number"
                            step="0.01"
                            name="Estimated Hour"
                            {...register(`labourItem.${i}.estimated_hour`, {
                              required: '**Field is required and needs to be a text',
                            })}
                            onChange={(e, val) => {
                              calculateTotalHours(i, e.target.value);
                            }}
                            error={Boolean(errors.labourItem ? errors.labourItem[i]?.estimated_hour : null)}
                            helperText={errors.labourItem ? errors.labourItem[i]?.estimated_hour?.message : null}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 15 }}
                          />
                        </Grid>
                      </Grid>
                      <Grid mt={2}>
                        <Grid item>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={getValues(`labourItem.${i}.text_exempt`)}
                                  defaultChecked={params && editData?.billable_line_items?.labourItem[i]?.text_exempt}
                                  onChange={(e, val) => {
                                    setValue(`labourItem.${i}.text_exempt`, val);
                                    setLabourcheckbox(`labourItem.${i}.text_exempt`, val);
                                  }}
                                />
                              }
                              label="Text Exempt"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
              </>
            ))}
            <Grid mt={2}>
              <Button
                variant="contained"
                sx={{ marginTop: '20px' }}
                onClick={() => handleAddLabour(labourItem.length)}
                startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
              >
                Add Labour Cost Item
              </Button>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
              Equipment and Materials
            </Typography>
            {materialItem?.map((item, i) => (
              <>
                <Grid key={i}>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ marginRight: '10px' }}>
                      <CancelIcon
                        key={i}
                        onClick={() => handleMaterialDelete(i)}
                        sx={{ fontSize: '42px', color: 'red' }}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        {''}
                      </CancelIcon>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid sx={{ marginLeft: '20px' }} direction={'row'} container spacing={5}>
                      <Grid container direction={'row'} mt={4} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>Cost Item</>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`materialItem.${i}.costItem`}
                            setValue={setValue}
                            data={costItemData
                              ?.filter((item) => item?.category === categoryData[1]?.name)
                              .map((iteam) => {
                                return { label: iteam.name, id: iteam._id };
                              })}
                            validation={false}
                            errors={null}
                          />
                        </Grid>
                        {/* <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Name <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="text"
                            {...register(`materialItem.${i}.name`, {
                              required: '**Field is required and needs to be a text',
                            })}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.materialItem ? errors?.materialItem[i]?.name : null)}
                            helperText={
                              Boolean(errors.materialItem ? errors?.materialItem[i]?.name : false)
                                ? '*Field is required and needs to be a text'
                                : null
                            }
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid> */}
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`materialItem.${i}.unit`}
                            setValue={setValue}
                            data={UnitItem}
                            register={register}
                            validation={true}
                            errors={errors}
                          />
                        </Grid>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit Cost <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="number"
                            step="0.01"
                            {...register(`materialItem.${i}.unitCost`, {
                              required: '**Field is required and needs to be a number',
                              pattern: {
                                value: /^[0-9]/,
                                message: 'invalid input type',
                              },
                            })}
                            min={0}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.materialItem ? errors?.materialItem[i]?.unitCost : null)}
                            helperText={errors.materialItem ? errors?.materialItem[i]?.unitCost?.message : false}
                          />
                        </Grid>
                      </Grid>
                      <Grid mt={2} container>
                        <Grid item>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={getValues(`materialItem.${i}.text_exempt`) ? true : false}
                                  defaultChecked={params && editData?.billable_line_items?.materialItem[i]?.text_exempt}
                                  onChange={(e, val) => {
                                    setValue(`materialItem.${i}.text_exempt`, val);
                                    setMaterialcheckbox(val);
                                  }}
                                />
                              }
                              label="Text Exempt"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
              </>
            ))}
            <Grid mt={2}>
              <Button
                variant="contained"
                sx={{ marginTop: '20px' }}
                onClick={() => handleAddMaterial(materialItem.length)}
                startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
              >
                Add Equipment and Materials Item
              </Button>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
              Fixed Price
            </Typography>
            {fixItem?.map((item, i) => (
              <>
                <Grid key={i}>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ marginRight: '10px' }}>
                      <CancelIcon
                        key={i}
                        onClick={() => handleFixDelete(i)}
                        sx={{ fontSize: '42px', color: 'red' }}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        {''}
                      </CancelIcon>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid sx={{ marginLeft: '20px' }} direction={'row'} container spacing={5}>
                      <Grid container direction={'row'} mt={4} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>Cost Item </>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`fixItem.${i}.costItem`}
                            setValue={setValue}
                            data={costItemData
                              ?.filter((item) => item.category === categoryData[2]?.name)
                              .map((item) => {
                                return { label: item.name, id: item._id };
                              })}
                            register={register}
                            validation={false}
                            errors={null}
                          />
                        </Grid>
                        {/* <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Name <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="text"
                            {...register(`fixItem.${i}.name`, {
                              required: '**Field is required and needs to be a text',
                            })}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.fixItem ? errors.fixItem[i]?.name : null)}
                            helperText={
                              Boolean(errors.fixItem ? errors.fixItem[i]?.name : false)
                                ? '*Field is required and needs to be a text'
                                : null
                            }
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid> */}
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`fixItem.${i}.unit`}
                            setValue={setValue}
                            data={UnitItem}
                            register={register}
                            validation={true}
                            errors={errors}
                          />
                        </Grid>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit Cost<span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="number"
                            step="0.01"
                            {...register(`fixItem.${i}.unitCost`, {
                              required: '**Field is required and needs to be a number',
                              pattern: {
                                value: /^[0-9]/,
                                message: 'invalid input type',
                              },
                            })}
                            min={0}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.fixItem ? errors.fixItem[i]?.unitCost : null)}
                            helperText={errors.fixItem ? errors.fixItem[i]?.unitCost?.message : null}
                          />
                        </Grid>
                      </Grid>
                      <Grid mt={2} container>
                        <Grid item>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={getValues(`fixItem.${i}.text_exempt`) ? true : false}
                                  defaultChecked={params && editData?.billable_line_items?.fixItem[i]?.text_exempt}
                                  onChange={(e, val) => {
                                    setValue(`fixItem.${i}.text_exempt`, val);
                                    setfixedcheckbox(val);
                                  }}
                                />
                              }
                              label="Text Exempt"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
              </>
            ))}
            <Grid mt={2}>
              <Button
                variant="contained"
                sx={{ marginTop: '20px' }}
                onClick={() => handleAddFix(fixItem.length)}
                startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
              >
                Add Fixed Price Item
              </Button>
            </Grid>
          </TabPanel>
        </Card>

        <Grid>
          <Grid sx={{ display: 'flex' }}>
            <Divider orientation="vertical" variant="" flexItem />
            <Grid sx={{ marginLeft: '20px' }} direction={'row'} container spacing={5}>
              <Grid container direction={'row'} mt={4} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                <Grid xs={12} item md={6}>
                  <FormLabel>
                    <>Total Cost Hour</>
                  </FormLabel>
                  <TextField
                    fullWidth
                    type="number"
                    step="0.01"
                    min={0}
                    value={TotalCostHour}
                    InputLabelProps={{ shrink: true }}
                    error={Boolean(errors ? errors.total_costhour : null)}
                    helperText={errors ? errors.total_costhour?.message : null}
                  />
                </Grid>
                <Grid md={6} item xs={12}>
                  <FormLabel>
                    <>Total Estimated Hour</>
                  </FormLabel>
                  <TextField
                    fullWidth
                    type="number"
                    step="0.01"
                    name="Total Estimated Hour"
                    value={totalEstimatedHour}
                    error={Boolean(errors ? errors.total_estimated_hour : null)}
                    helperText={errors ? errors.total_estimated_hour?.message : null}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider style={{ marginBlock: 30 }} orientation="horizontal" variant="middle" flexItem />

        <Grid>
          <Grid container direction={'row'} colSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
            <Grid item xs={12} md={6}>
              <FormLabel>
                <>Remarks</>
              </FormLabel>
              <TextField
                multiline
                rows={3}
                fullWidth
                {...register(`remark`)}
                onChange={(e, val) => {
                  settextAreaCount(e.target.value.length);
                  setValue('remark', e.target.value);
                }}
                inputProps={{ maxLength: 300 }}
                InputLabelProps={{ shrink: true }}
              />
              <Grid sx={{ fontSize: '12px', textAlign: 'end' }}> {textAreaCount} / 300</Grid>
            </Grid>
            <Grid xs={12} item md={6}>
              <FormLabel>
                <>Attachment</>
              </FormLabel>
              <TextField
                type="file"
                fullWidth
                onChange={(e) => handleFile(e)}
                inputProps={{ maxLength: 300 }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </Grid>

        <FormGroup>
          <FormControlLabel
            control={
              <>
                <Checkbox
                  defaultChecked={params && getValues('active') ? true : false}
                  checked={getValues('active') ? true : false}
                  onChange={(e, val) => {
                    setValue('active', val);
                    setcheckbox(val);
                  }}
                />
              </>
            }
            label="Active"
          />
        </FormGroup>

        <Grid sx={{ display: 'flex' }}>
          <LoadingButton
            type="submit"
            size="large"
            variant="contained"
            loading={loading}
            disabled={isValidate ? false : true}
            style={{ width: 300 }}
          >
            Submit
          </LoadingButton>
          {params && (
            <Button
              style={{
                width: 300,
                marginLeft: '20px',
              }}
              variant="contained"
              size="large"
              color="error"
              fullWidth
              onClick={() => {
                navigate('manager/quote/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form>
  );
}

ManagersideQuoteCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func,
};
