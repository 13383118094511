

import React, { useCallback, useContext, useEffect } from 'react';
import { useParams, useNavigate, json } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import ManagerDailyWorkReportCreateForm from './ManagerDailyWorkReportCreateForm';

const { PRIVATE_ROUTE } = API_ROUTES;

function ManagerDailyWorkReportForm() {

  const { id } = useParams();
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();

  const [taskdata, settaskData] = React.useState([]);
  const [costItemData, setcostItemData] = useState([]);
  const [categoryData, setcategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [state, setstate] = useState();
  const [attachments, setAttachments] = React.useState('');
  const [signOne, setSignOne] = useState('');
  const [signTwo, setSignTwo] = useState('');
  const [mySignature, setMySignature] = useState('');
  const [jobIdValue, setJobIdValue] = useState();
  const [joballData, setjoballData] = useState([]);


  console.log(taskdata);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    watch,
    control
  } = useForm(!id ?
    {
      defaultValues: {
        task_name: '',
        // description: "",
        task_hour: "",
        representative_sign: '',
        client_representative_sign: "",
        task_date: new Date(),
        client_approved_DWR: '',
        remark: '',
        submit_status: "",

      }
    } :
    {
      defaultValues: {
        task_name: '',
        // description: "",
        task_hour: "",
        representative_sign: '',
        client_representative_sign: "",
        task_date: new Date(),
        client_representative: '',
        client_approved_DWR: '',
        remark: '',
        submit_status: "",
      }
    }
  );



  const getDWRFormById = useCallback(async () => {
    const { data } = await authAxios.get(
      `${PRIVATE_ROUTE.GET_DWR_BY_ID}/${id}`
    );
    console.log("data----->", data);

    console.log(71, data?.data[0]?.client_representative);
    if (data) {

      setstate(data?.data[0]);
      setJobIdValue(data?.data[0]?.taskdata?.job_id);
      setValue('task_name', data?.data[0]?.taskdata?.number_str);
      setValue('task_id', data?.data[0]?.task_id);
      setValue('job_id', data?.data[0]?.job_id);
      setValue('job', data?.data[0]?.taskdata?.job_id);
      setValue('project_manager_id', data?.data[0]?.task_id);
      setValue('project_managers_array', data?.data[0]?.project_manager);

      setValue('client_representative', data?.data[0]?.client_representative);
      setValue('client_representative_id ', data?.data[0]?.client_representative);
      // setValue('description', data?.data[0]?.description);
      setValue('task_date', data?.data[0]?.task_date);
      setValue('task_hour', data?.data[0]?.task_hour);
      setValue('representative_sign', data?.data[0]?.representative_sign);
      setValue('client_representative_sign', data?.data[0]?.client_representative_sign);
      setValue('client_approved_DWR', data?.data[0]?.client_approved_DWR);
      setValue('submit_status', data?.data[0]?.submit_status);
      setValue('remark', data?.data[0].remark);
      setAttachments(data?.data[0]?.client_approved_DWR);
      setMySignature("");
      setSignOne(data?.data[0]?.representative_sign);
      setSignTwo(data?.data[0]?.client_representative_sign);
      data?.data[0]?.billing_line_items?.labourCosts?.map((element, i) => {
        setValue(`labourCosts.${i}.costitem`, element?.costitem)
        setValue(`labourCosts.${i}.employee`, element?.employee)
        setValue(`labourCosts.${i}.start_time`, element?.start_time)
        setValue(`labourCosts.${i}.end_time`, element?.end_time)
        setValue(`labourCosts.${i}.hours`, element?.hours)
        setValue(`labourCosts.${i}.description`, element?.description)
      })
      data?.data[0]?.billing_line_items?.equipment?.map((element, i) => {
        setValue(`equipment.${i}.costitem`, element?.costitem)
        setValue(`equipment.${i}.name`, element?.name)
        setValue(`equipment.${i}.unit`, element?.unit)
        setValue(`equipment.${i}.quantity`, element?.quantity)
        setValue(`equipment.${i}.discription`, element?.discription)
        setValue(`equipment.${i}.include_discription`, element?.include_discription)

      })
    }

  }, [authAxios, id, setValue]);

  const gettaskdata = useCallback(async () => {
    // const { data } = await authAxios.get(
    //   `${PRIVATE_ROUTE.GET_TASKALL}`
    // );
    const costItemdata = await authAxios.get(
      `${PRIVATE_ROUTE.GET_COSTITEAMALL}`
    );
    const categorydata = await authAxios.get(
      `${PRIVATE_ROUTE.GET_CATEGORY}`
    );
    // settaskData(data?.data);
    console.log("costItemdata----->", costItemdata);

    setcostItemData(costItemdata?.data?.data)
    setcategoryData(categorydata?.data?.data)
    if (id) {
      getDWRFormById();
    }
    setLoading(false)
  }, [authAxios])



  const getclientdata = useCallback(async () => {
    const jobdata = await authAxios.get(`${PRIVATE_ROUTE.GET_JOBALL}`);
    setjoballData(jobdata.data.data);
  }, [authAxios, id]);

  useEffect(() => {
    getclientdata();
  }, []);

  useEffect(() => {
    gettaskdata()
  }, [gettaskdata, id]);

  const onSubmit = async (data) => {
    console.log('submit--->', data);
    const user_data = JSON.parse(localStorage.getItem("LAND-FRONTED"))
    const newdata = { ...data, task_id: data?.task_id?.trim(), job_id: data?.job_id?.trim(), user_id: user_data.user._id }
    if (id) {
      const response = await authAxios.post(
        `${PRIVATE_ROUTE?.EDIT_DWR}${id}`,
        newdata
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.massage);
        navigate('/manager/dwr/list');
      }
      else {
        toast.error(response.data.massage);
      }

    } else {

      const response = await authAxios.post(PRIVATE_ROUTE?.CREATE_DWR, newdata);
      if (response.data.statusCode === 200) {

        toast.success(response.data.massage);
        navigate('/manager/dwr/list');
      }
      else {

        toast.error(response.data.massage);
      }
    }
    setValue('task_name', "");
    setValue('job', "");

    // setValue('description', "");
    setValue('task_date', "");
    setValue('task_hour', "");
    setValue('representative_sign', "");
    setValue('client_representative_sign', "");
    setValue('client_approved_DWR', "");
    setValue('submit_status', "");
    setValue('client_representative', "");
    setValue('project_manager_id', "");
    setValue('remark', "");
    state?.billing_line_items?.labourCosts?.map((element, i) => {
      setValue(`labourCosts.${i}.costitem`, "")
      setValue(`labourCosts.${i}.employee`, "")
      setValue(`labourCosts.${i}.start_time`, "")
      setValue(`labourCosts.${i}.end_time`, "")
      setValue(`labourCosts.${i}.hours`, "")
      setValue(`labourCosts.${i}.description`, "")
    })
    state?.billing_line_items?.equipment?.map((element, i) => {
      setValue(`equipment.${i}.costitem`, "")
      setValue(`equipment.${i}.name`, "")
      setValue(`equipment.${i}.unit`, "")
      setValue(`equipment.${i}.quanitity`, "")
      setValue(`equipment.${i}.discription`, "")
      setValue(`equipment.${i}.include_discription`, "")

    })
  };
  return (
    <>


      {loading ? (
        <div>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      ) : (
        ''
      )}

      <ManagerDailyWorkReportCreateForm
        getValues={getValues}
        register={register}
        watch={watch}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        params={id}
        loading={isSubmitting}
        isEdit={id ? "true" : "false"}
        control={control}
        setValue={setValue}
        taskdata={taskdata}
        costItemData={costItemData}
        categoryData={categoryData}
        editdata={state}
        attachments={attachments}
        setAttachments={setAttachments}
        signOne={signOne}
        setSignOne={setSignOne}
        signTwo={signTwo}
        setSignTwo={setSignTwo}
        mySignature={mySignature}
        setMySignature={setMySignature}
        authAxios={authAxios}
        PRIVATE_ROUTE={PRIVATE_ROUTE}
        joballData={joballData}
        jobIdValue={jobIdValue}
        setJobIdValue={setJobIdValue}
      />
    </>
  );
}

export default ManagerDailyWorkReportForm;
