import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider,
  createTheme,
  Box,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MUIDataTable from 'mui-datatables';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { useNavigate, Link } from 'react-router-dom';
import clsx from 'clsx';
import Scrollbar from 'src/components/scrollbar';
import Iconify from '../../../iconify';
import Icon from 'src/components/color-utils/Icon';
import { AuthContext } from "src/context/AuthContext";

// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function ManagersideTasklist() { const [open, setOpen] =
useState(null); const [selectedRow, setSelectedRow] = useState(null); const
[tasks, setTasks] = useState([]); const [loading, setLoading] = useState
(true); const [count, setCount] = useState(1); const
[rowsPerPage, setRowsPerPage] = useState(10); const [page, setPage] =
useState(null); const { authAxios } = useContext(FetchContext); const
[sortEnable, setSortEnable] = useState({}); const
[searchEnable, setSearchEnable] = useState();
const { state } = useContext(AuthContext);

  const navigate = useNavigate();
  // const handleOpenMenu = (event, id) => {
  //   setOpen(event.currentTarget);
  //   setSelectedRow(id);
  // };
  const handleEditRedirect = (e, id) => {
    setOpen(null);
    setSelectedRow(null);
    navigate(`/manager/task/update/${id}`);
  };
  const handleRowDelete = async (e,id) => {
    await authAxios.post(`${PRIVATE_ROUTE?.DELETE_TASK}${id}`);
    getTaskdata('');
    setOpen(null);
    setSelectedRow(null);
  };
  // const handleCloseMenu = () => {
  //   setOpen(null);
  //   setSelectedRow(null);
  // };
  const handlejobcreateform = () => {
    navigate('/manager/task/create');
  };
  const getTaskdata = (query) => {
    let sort_params;
    let page_cout = query.page_no ? query.page_no : 0;
    if (typeof query !== 'string') {
      console.log('here search', query.searchQuery);

      sort_params = `&sortField=${query.sortField}&sortOrder=${query.sortOrder}&search=${query.searchQuery ? query.searchQuery : ''
        }`;
    } else {
      sort_params = `&search=${query}`;
    }
    let isMounted = true;
    const getTasks = async () => {
      // const { data } = await authAxios.get(
      //   `${PRIVATE_ROUTE?.GET_TASK}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}&is_complete=1`
      // );
      const { data } = await authAxios.get(
        `${PRIVATE_ROUTE?.GET_TASK}?page=${page_cout + 1}&per_page=${rowsPerPage}${sort_params}&is_complete=1&user_id=${state.user._id}`
      );
      if (isMounted) {
        setTasks(
          data?.data?.map((item) => {
            console.log('dataaa', data);
            const project_managers_name = item.project_managers.map(
              (pmdetails) => pmdetails.first_name + ' ' + pmdetails.last_name
            );
            return {
              _id: item._id,
              name: item.name,
              task_number: (
                <Link to={`/manager/task/view/${item._id}`}>
                  <b>{item.number_str}</b>
                </Link>
              ),
              job_number: (
                <Link to={`/manager/job/view/${item.job_id._id}`}>
                  <b>{item.job_id.number_str}</b>
                </Link>
              ),
              client_number: item.client_id.company_name,
              quote_number: (
                <Link to={`/manager/quote/view/${item?.quote_id?._id}`}>
                  <b>{item?.quote_id?.number_str}</b>
                </Link>
              ),
              status: item.status_id.name,
              is_completed: item.is_completed,
              ratesheet: item.ratesheet_id.name,
              manager: project_managers_name.join(', '),
              active: <Icon color={item.active === true ? 'green' : 'red'} name="people" size={30} />,
            };
          })
        );
        setCount(data.total);
        setLoading(false);
      }
    };
    getTasks().catch((err) => {
      if (!isMounted) return;
      console.error('failed to fetch data', err);
      setLoading(false);
    });
    // getUsers()
    return () => {
      isMounted = false;
    };
  };
  useEffect(() => {
    getTaskdata('');
  }, [rowsPerPage]);

  const columns = [
    {
      name: '_id',
      label: 'Id',
      options: {
        draggable: true,
        sort: false,
        filter: false,
        display: false,
      },
    },
    {
      name: 'is_completed',
      label: 'is_completed',
      options: {
        draggable: true,
        sort: false,
        filter: false,
        display: false,
      },
    },
    {
      name: 'task_number',
      label: 'Task Number',
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true,
      },
    },
    {
      name: `job_number`,
      label: 'Job Number',
      options: {
        draggable: false,
        sort:true,
        filter: true,
        display: true,
      },
    },
    {
      name: 'client_number',
      label: 'Client',
      options: {
        draggable: false,
        sort:false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'quote_number',
      label: 'Quote Number',
      options: {
        draggable: false,
        sort:false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'manager',
      label: 'Project Manager',
      options: {
        draggable: false,
        sort:false,
        filter: true,
        display: true,
      },
    },
    // {
    //   name: 'name',
    //   label: 'Name',
    //   options: {
    //     draggable: false,
    //     // sort:true,
    //     filter: true,
    //     display: true,
    //   },
    // },
    {
      name: 'ratesheet',
      label: 'RateSheet',
      options: {
        draggable: false,
        sort:false,
        filter: true,
        display: true,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        draggable: false,
        sort:false,
        filter: true,
        display: true,
      },
    },
    {
      name: `active`,
      label: 'Active',
      options: {
        draggable: false,
        sort:false,
        filter: true,
        display: true,
      },
    },
    {
      name: '',
      lable: 'Action',
      options: {
        filter: true,
        sort:false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log('tableMeta', tableMeta);
          return (
            // <IconButton color="inherit" onClick={(e) => handleOpenMenu(e, tableMeta.rowData[0])}>
            //   <Iconify width={15} icon={'eva:more-vertical-fill'} />
            // </IconButton>
            <div style={{ display: 'flex', gap: '5px' }}>
              <EditIcon
                color={tableMeta.rowData[1] ? 'disabled' : 'inherit'}
                onClick={tableMeta.rowData[1] ? undefined : (e) => handleEditRedirect(e, tableMeta.rowData[0])}
              />
              <DeleteIcon
                color={tableMeta.rowData[1] ? 'disabled' : 'inherit'}
                onClick={tableMeta.rowData[1] ? undefined : (e) => handleRowDelete(e, tableMeta.rowData[0])}
              />
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectToolbarPlacement: 'none',
    tableId: 'HelloWorld',
    rowHover: false,
    jumpToPage: true,
    responsive: 'standerd',
    print: true,
    filter: false,
    fixedHeader: true,
    searchOpen: true,
    count: count,
    selectableRows: false,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',
        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: '1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)',
        size: 'small',
      };
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    // onSearchChange: (searchQuery) => {
    //   if (searchQuery !== null && searchQuery.length > 2) {
    //     getTaskdata(searchQuery);
    //     setLoading(true);
    //   }
    //   if (searchQuery === null) {
    //     getTaskdata('');
    //   }
    // },

     onSearchChange: (searchQuery) => {
      if (searchQuery !== null && searchQuery.length > 2) {
        setSearchEnable(searchQuery);
        if (Object.keys(sortEnable).length !== 0) {
         
          const sortOrder = sortEnable.direction; // 'asc' or 'desc'
          getTaskdata({  sortOrder, searchQuery }); // Update your API call to include sorting
        } else {
          getTaskdata(searchQuery);
        }
        setLoading(true);
      }
      if (searchQuery === null) {
        if (Object.keys(sortEnable).length !== 0) {

          const sortOrder = sortEnable.direction; // 'asc' or 'desc'
          getTaskdata({  sortOrder }); // Update your API call to include sorting
        } else {
          getTaskdata('');
        }
      }
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    // onTableChange: (action, tableState) => {
    //   if (action === 'changePage') {
    //     setPage(tableState.page);
    //   }
    // },
    
    //  onTableChange: (action, tableState) => {
    //   console.log('action===>', action);

    //   const { name, direction } = tableState.sortOrder;
    //   const sortOrder = direction; // 'asc' or 'desc'
    //   let searchQuery = searchEnable;
    //   let page_no = tableState.page;
    //   if (action === 'sort') {
    //     setSortEnable(tableState.sortOrder);
    //     if (searchEnable) {
    //       getTaskdata({ sortOrder, searchQuery, page_no }); // Update your API call to include sorting
    //     } else {
    //       getTaskdata({ sortOrder, page_no }); // Update your API call to include sorting
    //     } // Update your API call to include sorting
    //   }
    //   if (action === 'changePage') {
    //     setPage(tableState.page);

    //     if (searchEnable) {
    //       getTaskdata({ sortOrder, searchQuery, page_no }); // Update your API call to include sorting
    //     } else {
    //       getTaskdata({ sortOrder, page_no }); // Update your API call to include sorting
    //     }
    //   }
    // },

    onTableChange: (action, tableState) => {
      console.log('action===>', action);

      const { name, direction } = tableState.sortOrder;
      const sortField = name; // Column name
      const sortOrder = direction; // 'asc' or 'desc'
      let searchQuery = searchEnable;
      let page_no = tableState.page;
      if (action === 'sort') {
        setSortEnable(tableState.sortOrder);
        if (searchEnable) {
          getTaskdata({ sortField, sortOrder, searchQuery, page_no }); // Update your API call to include sorting
        } else {
          getTaskdata({ sortField, sortOrder, page_no }); // Update your API call to include sorting
        } // Update your API call to include sorting
      }
      if (action === 'changePage') {
        setPage(tableState.page);

        if (searchEnable) {
          getTaskdata({ sortField, sortOrder, searchQuery, page_no }); // Update your API call to include sorting
        } else {
          getTaskdata({ sortField, sortOrder, page_no }); // Update your API call to include sorting
        }
      }
    },
  };

  return (
    <>
      <Grid sx={{ p: 4, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Complete Task
          </Typography>
          <Button variant="contained" onClick={handlejobcreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Task
          </Button>
        </Stack>

        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MUIDataTable options={options} columns={columns} data={tasks} />
                {/* <Popover
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                      },
                    },
                  }}
                >
                  <MenuItem onClick={handleEditRedirect}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                  </MenuItem>

                  <MenuItem onClick={handleRowDelete} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                  </MenuItem>
                </Popover> */}
                {loading && (
                  <div>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                )}

                {!tasks && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </>
  );
}
