import * as React from 'react';
import { useContext } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Card,
  Grid,
  Stack,
  TextField,
  Typography,
  useAutocomplete,
  TextareaAutosize,
  FormLabel,
  FormControl,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Controller, useFieldArray } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import CancelIcon from '@mui/icons-material/Cancel';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';
import { CheckBox } from '@mui/icons-material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function RateSheetCreateForm({
  register,
  errors,
  setValue,
  categoryData,
  costItemData,
  isEdit,
  getValues,
  handleSubmit,
  onSubmit,
  params,
  loading,
  control,
  editData,
}) {
  const filterdata = costItemData?.filter((item) => {
    return item.category === categoryData[0]?.name;
  })
    .map((item) => {
      return { label: item.name, id: item._id };
    })
  const navigate = useNavigate();
  const [textAreaCount, settextAreaCount] = React.useState(0);
  const [checkbox, setcheckbox] = React.useState();
  const [labourcheckbox, setLabourcheckbox] = React.useState();
  const [materialcheckbox, setMaterialcheckbox] = React.useState();
  const [fixedcheckbox, setfixedcheckbox] = React.useState();
  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const { fields: labourItem, remove: removeLabour, insert: insertLabour } = useFieldArray({
    control,
    name: 'labourItem',
  });
  const { fields: materialItem, remove: removeMaterial, insert: insertMaterial } = useFieldArray({
    control,
    name: 'materialItem',
  });
  const { fields: fixItem, remove: removeFix, insert: insertFix } = useFieldArray({
    control,
    name: 'fixItem',
  });
  const handleLabourDelete = (i) => {
    removeLabour(i, 1);
  };
  const handleMaterialDelete = (i) => {
    removeMaterial(i, 1);
  };
  const handleFixDelete = (i) => {
    removeFix(i, 1);
  };
  const handleAddLabour = () => {
    insertLabour(labourItem?.length, {
      costItem: '',
      name: '',
      unit: '',
      unitCost: '',
      hourlyCost: '',
      text_exempt: false,
    }
    );
  };
  const handleAddMaterial = () => {
    insertMaterial(materialItem?.length, {
      costItem: '',
      name: '',
      unit: '',
      unitCost: '',
      text_exempt: false,
    });
  };
  const handleAddFix = () => {
    insertFix(fixItem?.length, {
      costItem: '',
      name: '',
      unit: '',
      unitCost: '',
      text_exempt: false,
    });
  };

  let editlabourcheckbox;
  editData?.billable_line_items?.labourItem?.map((element, i) => {
  editlabourcheckbox = getValues(`labourItem.${i}.text_exempt`)
  })
console.log("150",editlabourcheckbox);

  React.useEffect(() => {
      setLabourcheckbox(editlabourcheckbox || null);
}, [editlabourcheckbox])  ;

  React.useEffect(() => {

    if (params && editData?.billable_line_items?.labourItem?.length !== 0) {
      editData?.billable_line_items?.labourItem?.map((element, i) => {
        handleAddLabour();
        handleLabourDelete(labourItem?.length);
      });
    }
    if (params && editData?.billable_line_items?.materialItem?.length !== 0) {
      editData?.billable_line_items?.materialItem?.map((element, i) => {
        handleAddMaterial();
        handleMaterialDelete(materialItem?.length)
      });
    }
    if (params && editData?.billable_line_items?.fixItem?.length !== 0) {
      editData?.billable_line_items?.fixItem?.map((element, i) => {
        handleAddFix();
        handleFixDelete(fixItem.length)
      });
    }
  }, [editData]);


  const UnitItem = [
    {
      label: 'HR',
      id: 'HR',
    },
    {
      label: 'DAY',
      id: 'DAY',
    },
    {
      label: 'PER',
      id: 'PER',
    },
    {
      label: 'UNIT',
      id: 'UNIT',
    },
  ];



  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} padding={3}>
        {
          params ?
            <>
              <Typography variant="h4" gutterBottom>
                Edit RateSheet
              </Typography>
            </>
            :
            <>
              <Typography variant="h4" gutterBottom>
                Create RateSheet
              </Typography>
            </>
        }

        <Grid>
          <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
            <Grid xs={12} item md={6}>
              <FormLabel>
                <>
                  Ratesheet Number<span style={{ color: 'red' }}>*</span>
                </>
              </FormLabel>
              <TextField
                fullWidth
                type="number"
                {...register('name', {
                  required: '**Field is required and needs to be a text',
                })}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid xs={12} item md={6}>
              <FormLabel>
                <>Remarks</>
              </FormLabel>
              <TextField
                multiline
                rows={3}
                {...register('remark')}
                fullWidth
                onChange={(e, val) => {
                  settextAreaCount(e.target.value.length);
                  setValue('remark', e.target.value);
                }}
                inputProps={{ maxLength: 300 }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item sx={{ fontSize: '12px', textAlign: 'end' }} xs={12}>
              {' '}
              {textAreaCount} / 300
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="h6" component="h2">
          Billing Line Items
        </Typography>
        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Labour Costs" {...a11yProps(0)} />
              <Tab label="Equipment and Materials" {...a11yProps(1)} />
              <Tab label="Fixed Price" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
              Labour Costs
            </Typography>
            {labourItem?.map((item, i) => (
              <>
                <Grid key={i}>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ marginRight: '10px' }}>
                      <CancelIcon
                        key={i}
                        onClick={() => handleLabourDelete(i)}
                        sx={{ fontSize: '42px', color: 'red' }}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        {''}
                      </CancelIcon>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid sx={{ marginLeft: '20px' }} direction={'row'} container spacing={5}>
                      <Grid container direction={'row'} mt={4} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Cost Item <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`labourItem.${i}.costItem`}
                            setValue={setValue}
                            data={filterdata}
                            validation={true}
                            register={register}
                            errors={errors}
                          />
                        </Grid>
                        {/* <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Name<span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="text"
                            {...register(`labourItem.${i}.name`, {
                              required: '**Field is required and needs to be a text',
                            })}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.labourItem ? errors.labourItem[i]?.name : null)}
                            helperText={
                              Boolean(errors.labourItem ? errors.labourItem[i]?.name : false)
                                ? '*Field is required and needs to be a text'
                                : null
                            }
                            inputProps={{ maxLength: 50 }}

                          />
                        </Grid> */}
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit<span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`labourItem.${i}.unit`}
                            setValue={setValue}
                            data={UnitItem}
                            register={register}
                            validation={true}
                            errors={errors}
                            control={control}
                          />
                        </Grid>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit Cost<span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              fullWidth
                              type="number"
                              step="0.01"
                              {...register(`labourItem.${i}.unitCost`, {
                                required: '**Field is required and needs to be a number',
                                pattern: {
                                  value: /^[0-9]/,
                                  message: 'invalid input type'
                                }
                              })}
                              InputLabelProps={{ shrink: true }}
                            
                              min={0}
                              error={Boolean(errors.labourItem ? errors.labourItem[i]?.unitCost : null)}
                              helperText={errors.labourItem ? errors.labourItem[i]?.unitCost?.message : null
                              }
                            />
                          </FormControl>
                        </Grid>
                        {/* <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>Hourly Cost</>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="number"
                            step="0.01"
                            {...register(`labourItem.${i}.hourlyCost`, {
                              pattern: {
                                value: /^[0-9]/,
                                message: 'invalid input type'
                              }
                            })}
                          
                            min={0}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.labourItem ? errors.labourItem[i]?.hourlyCost : null)}
                            helperText={errors.labourItem ? errors.labourItem[i]?.hourlyCost?.message : null
                            }
                          />
                        </Grid> */}
                      </Grid>
                      <Grid mt={2}>
                        <Grid item>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={getValues(`labourItem.${i}.text_exempt`)}
                                  defaultChecked={params && editData?.billable_line_items?.labourItem[i]?.text_exempt}
                                  onChange={(e, val) => {
                                    setValue(`labourItem.${i}.text_exempt`, val);
                                    setLabourcheckbox(`labourItem.${i}.text_exempt`,val);
                                  }}
                                />
                              }
                              label="Text Exempt"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
              </>
            ))}
            <Grid mt={2}>
              <Button
                variant="contained"
                sx={{ marginTop: '20px' }}
                onClick={() => handleAddLabour(labourItem.length)}
                startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
              >
                Add Labour Cost Item
              </Button>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
              Equipment and Materials
            </Typography>
            {materialItem?.map((item, i) => (
              <>
                <Grid key={i}>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ marginRight: '10px' }}>
                      <CancelIcon
                        key={i}
                        onClick={() => handleMaterialDelete(i)}
                        sx={{ fontSize: '42px', color: 'red' }}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        {''}
                      </CancelIcon>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid sx={{ marginLeft: '20px' }} direction={'row'} container spacing={5}>
                      <Grid container direction={'row'} mt={4} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>Cost Item</>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`materialItem.${i}.costItem`}
                            setValue={setValue}
                            data={costItemData
                              ?.filter((item) => item?.category === categoryData[1]?.name)
                              .map((iteam) => {
                                return { label: iteam.name, id: iteam._id };
                              })}
                            validation={false}
                            errors={null}
                          />
                        </Grid>
                        {/* <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Name <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="text"
                            {...register(`materialItem.${i}.name`, {
                              required: '**Field is required and needs to be a text',
                            })}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.materialItem ? errors?.materialItem[i]?.name : null)}
                            helperText={
                              Boolean(errors.materialItem ? errors?.materialItem[i]?.name : false)
                                ? '*Field is required and needs to be a text'
                                : null
                            }
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid> */}
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`materialItem.${i}.unit`}
                            setValue={setValue}
                            data={UnitItem}
                            register={register}
                            validation={true}
                            errors={errors}
                          />
                        </Grid>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit Cost <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            step="0.01"
                            type="number"
                            {...register(`materialItem.${i}.unitCost`, {
                              required: '**Field is required and needs to be a number',
                              pattern: {
                                value: /^[0-9]/,
                                message: 'invalid input type'
                              }
                            })}
                           
                            min={0}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.materialItem ? errors?.materialItem[i]?.unitCost : null)}
                            helperText={
                              errors.materialItem ? errors?.materialItem[i]?.unitCost?.message : false
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid mt={2} container>
                        <Grid item>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={getValues(`materialItem.${i}.text_exempt`) ? true : false}
                                  defaultChecked={params && editData?.billable_line_items?.materialItem[i]?.text_exempt}
                                  onChange={(e, val) => {
                                    setValue(`materialItem.${i}.text_exempt`, val);
                                    setMaterialcheckbox(val);
                                  }}
                                />
                              }
                              label="Text Exempt"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
              </>
            ))}
            <Grid mt={2}>
              <Button
                variant="contained"
                sx={{ marginTop: '20px' }}
                onClick={() => handleAddMaterial(materialItem.length)}
                startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
              >
                Add Equipment and Materials Item
              </Button>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
              Fixed Price
            </Typography>
            {fixItem?.map((item, i) => (
              <>
                <Grid key={i}>
                  <Grid sx={{ display: 'flex' }}>
                    <Grid sx={{ marginRight: '10px' }}>
                      <CancelIcon
                        key={i}
                        onClick={() => handleFixDelete(i)}
                        sx={{ fontSize: '42px', color: 'red' }}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        {''}
                      </CancelIcon>
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid sx={{ marginLeft: '20px' }} direction={'row'} container spacing={5}>
                      <Grid container direction={'row'} mt={4} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>Cost Item </>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`fixItem.${i}.costItem`}
                            setValue={setValue}
                            data={costItemData
                              ?.filter((item) => item.category === categoryData[2]?.name)
                              .map((item) => {
                                return { label: item.name, id: item._id };
                              })}
                            register={register}
                            validation={false}
                            errors={null}
                          />
                        </Grid>
                        {/* <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Name <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="text"
                            {...register(`fixItem.${i}.name`, {
                              required: '**Field is required and needs to be a text',
                            })}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.fixItem ? errors.fixItem[i]?.name : null)}
                            helperText={
                              Boolean(errors.fixItem ? errors.fixItem[i]?.name : false)
                                ? '*Field is required and needs to be a text'
                                : null
                            }
                            inputProps={{ maxLength: 50 }}
                          />
                        </Grid> */}
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit <span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <ChipDropdown
                            sx={{ width: '50%' }}
                            getValues={getValues}
                            state={`fixItem.${i}.unit`}
                            setValue={setValue}
                            data={UnitItem}
                            register={register}
                            validation={true}
                            errors={errors}
                          />
                        </Grid>
                        <Grid xs={12} item md={6}>
                          <FormLabel>
                            <>
                              Unit Cost<span style={{ color: 'red' }}>*</span>
                            </>
                          </FormLabel>
                          <TextField
                            fullWidth
                            type="number"
                            step="0.01"
                            {...register(`fixItem.${i}.unitCost`, {
                              required: '**Field is required and needs to be a number',
                              pattern: {
                                value: /^[0-9]/,
                                message: 'invalid input type'
                              }
                            })}
                           
                            min={0}
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(errors.fixItem ? errors.fixItem[i]?.unitCost : null)}
                            helperText={
                              errors.fixItem ? errors.fixItem[i]?.unitCost?.message : null
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid mt={2} container>
                        <Grid item>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  // defaultChecked={
                                  //   params && getValues(`fixItem.${i}.text_exempt`) ? true : false
                                  // }
                                  // checked={getValues(`fixItem.${i}.text_exempt`) ? true : false}

                                  checked={getValues(`fixItem.${i}.text_exempt`) ? true : false}
                                  defaultChecked={params && editData?.billable_line_items?.fixItem[i]?.text_exempt}
                                  onChange={(e, val) => {
                                    setValue(`fixItem.${i}.text_exempt`, val);
                                    setfixedcheckbox(val);
                                  }}
                                />
                              }
                              label="Text Exempt"
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
              </>
            ))}
            <Grid mt={2}>
              <Button
                variant="contained"
                sx={{ marginTop: '20px' }}
                onClick={() => handleAddFix(fixItem.length)}
                startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
              >
                Add Fixed Price Item
              </Button>
            </Grid>
          </TabPanel>
        </Card>
        <FormGroup>
          <FormControlLabel
            control={
              <>
                <Checkbox
                  defaultChecked={params && getValues('active') ? true : false}
                  checked={getValues('active') ? true : false}
                  onChange={(e, val) => {
                    setValue('active', val);
                    setcheckbox(val);
                  }}
                />
              </>
            }
            label="Active"
          />
        </FormGroup>
        <Grid sx={{ display: 'flex' }}>
          <LoadingButton type="submit" size="large" variant="contained" loading={loading} style={{ width: 300 }}>
            Submit
          </LoadingButton>
          {params && (
            <Button
              style={{
                width: 300,
                marginLeft: '20px',
              }}
              variant="contained"
              size="large"
              color="error"
              fullWidth
              onClick={() => {
                navigate('/rate-sheet/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form>
  );
}
RateSheetCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func,
};
