import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider, createTheme, Box, CircularProgress, Backdrop
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Scrollbar from "src/components/scrollbar";
import Iconify from '../../../iconify';
import Icon from "src/components/color-utils/Icon";
import { AuthContext } from "src/context/AuthContext";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function ManagerJoblist() {
  const [open, setOpen] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);
  const [sortEnable, setSortEnable] = useState({});
  const [searchEnable, setSearchEnable] = useState();

  const { state, dispatch } = useContext(AuthContext);
 console.log(state)
  const navigate = useNavigate();
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedRow(id);
  };
 const handleEditRedirect = (e, id) => {
    navigate(`/manager/job/update/${id}`);
  }
  const handleRowDelete = async (e, id) => {
    if (confirm("Are you sure want to delete?") === true) {
      setLoading(false);
    await authAxios.post(`${PRIVATE_ROUTE?.DELETE_JOB}${id}`);
    getUserdata("")
    setOpen(null);
    setLoading(true)
    setSelectedRow(null);
  }
}
  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedRow(null);
  };
  const handlejobcreateform = () => {
    navigate('/manager/job/create');
  }
  const getUserdata = (query) => {
     let sort_params;
    let page_cout = query.page_no ? query.page_no : 0;
    if (typeof query !== 'string') {
      console.log('here search', query.searchQuery);

      sort_params = `&sortOrder=${query.sortOrder}&search=${query.searchQuery ? query.searchQuery : ''
        }`;
    } else {
      sort_params = `&search=${query}`;
    }
    let isMounted = true;
    const getUsers = async () => {
      // const { data } = await authAxios.get(`${PRIVATE_ROUTE?.GET_JOB}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}`);
      const { data } = await authAxios.get(
        `${PRIVATE_ROUTE?.GET_JOB}?page=${page_cout + 1}&per_page=${rowsPerPage}${sort_params}`
      );
      if (isMounted) {
        setUsers(data?.data?.map((item,index) => {
          return (
            {
              _id:item._id,
              job_number: <Link to={`/manager/job/view/${item._id}`}><b>{(item.job_num)?.toString().padStart(6,'0')}</b></Link>,
              site_address:<Typography> {item?.locations?.[0]?.munciple_address} <br/> {item?.locations?.[0]?.city} <br/> {item?.locations?.[0]?.state} <br/> {item?.locations?.[0]?.postal_code}</Typography> ,
              job_status: item.status_id?.name,
              active: <Icon color={item.active === true ? "green" : "red"} name="people" size={30} />,
              order_date: item.order_date,
              due_date: item.due_date,
              remark: item.remark,
            })
        }));
        setCount(data.total);
        setLoading(false);
      }
    };

    getUsers().catch((err) => {
      if (!isMounted) return;
      console.error("failed to fetch data", err);
      setLoading(false);
    });
    // getUsers()
    return () => {
      isMounted = false;
    };
  }
  useEffect(() => {
    getUserdata("");
  }, [rowsPerPage]);

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        draggable: true,
        sort: false,
        filter: false,
        display: false
      }
    },
    {
      name: "job_number",
      label: "Job Number",
      options: {
        draggable: true,
        sort: true,
        filter: true,
        display: true
      }
    },
    {
      name: "site_address",
      label: "Site Address",
      options: {
        draggable: true,
        sort: false,
        filter: true,
        display: true
      }
    },
    {
      name: "job_status",
      label: "Job Status",
      options: {
        draggable: true,
        sort: false,
        filter: true,
        display: true
      }
    },
    {
      name: `active`,
      label: "Active",
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      }
    },
    {
      name: "order_date",
      label: "Order Date",
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      }
    },
    {
      name: "due_date",
      label: "Due Date",
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      }
    },
    {
      name: "remark",
      label: "Remark",
      options: {
        draggable: false,
        sort: false,
        filter: true,
        display: true,
      }
    },
    {
      name: "Tasks",
      lable: "Tasks",
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button variant="contained" onClick={(e) => navigate(`/manager/task/create`)}>
              add Task
            </Button>
          );
        },
      },
    },
    {
      name: "",
      lable: "Action",
      options: {
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{ display: 'flex', gap: '5px' }}>
            <EditIcon onClick={(e) => handleEditRedirect(e, tableMeta.rowData[0])} />
            <DeleteIcon onClick={(e) => handleRowDelete(e, tableMeta.rowData[0])} />
          </div>
          );
        },
      },
    },
  ];


  const options = {
    selectToolbarPlacement: 'none',
    tableId: "HelloWorld",
    rowHover: false,
    jumpToPage: true,
    responsive: "standerd",
    print: true,
    filter: false,
    fixedHeader: true,
    count: count,
    searchOpen: true,
    selectableRows: false,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',

        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: "1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)",
        size: 'small',
      };
    },
     // onSearchChange: (searchQuery) => {
    //   if (searchQuery !== null && searchQuery.length > 2) {
    //     getUserdata(searchQuery);
    //     setLoading(true);
    //   }
    //   if (searchQuery === null) {
    //     getUserdata("");
    //   }
    // },

       onSearchChange: (searchQuery) => {
      if (searchQuery !== null && searchQuery.length > 2) {
        setSearchEnable(searchQuery);
        if (Object.keys(sortEnable).length !== 0) {
         
          const sortOrder = sortEnable.direction; // 'asc' or 'desc'
          getUserdata({  sortOrder, searchQuery }); // Update your API call to include sorting
        } else {
          getUserdata(searchQuery);
        }
        setLoading(true);
      }
      if (searchQuery === null) {
        if (Object.keys(sortEnable).length !== 0) {

          const sortOrder = sortEnable.direction; // 'asc' or 'desc'
          getUserdata({  sortOrder }); // Update your API call to include sorting
        } else {
          getUserdata('');
        }
      }
    },

    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },

    rowsPerPage: rowsPerPage,
    serverSide: true,

    // onTableChange: (action, tableState) => {
    //   if (action === "changePage") {
    //     setPage(tableState.page);
    //   }
    // }
    
     onTableChange: (action, tableState) => {
      console.log('action===>', action);

      const { name, direction } = tableState.sortOrder;
      const sortOrder = direction; // 'asc' or 'desc'
      let searchQuery = searchEnable;
      let page_no = tableState.page;
      if (action === 'sort') {
        setSortEnable(tableState.sortOrder);
        if (searchEnable) {
          getUserdata({ sortOrder, searchQuery, page_no }); // Update your API call to include sorting
        } else {
          getUserdata({ sortOrder, page_no }); // Update your API call to include sorting
        } // Update your API call to include sorting
      }
      if (action === 'changePage') {
        setPage(tableState.page);

        if (searchEnable) {
          getUserdata({ sortOrder, searchQuery, page_no }); // Update your API call to include sorting
        } else {
          getUserdata({ sortOrder, page_no }); // Update your API call to include sorting
        }
      }
    },
  };

  return (
    <>


      <Grid sx={{ p: 4, pb: 0 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
           Job
          </Typography>
          <Button variant="contained" onClick={handlejobcreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Job
          </Button>
        </Stack>

        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MUIDataTable
                  options={options}
                  columns={columns}
                  data={users}
                />
                <Popover
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                      },
                    },
                  }}
                >
                  <MenuItem onClick={handleEditRedirect}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                  </MenuItem>

                  <MenuItem onClick={handleRowDelete} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                  </MenuItem>
                </Popover>
                {loading && (
                  <div>
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                   
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
                )}
                {!users && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>


    </>
  );
}
