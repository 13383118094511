import React, { useEffect, useContext, useCallback } from 'react'
import { Box, Button, Stack, Typography, Container, Card, Tabs, Tab, Grid, Divider, Table, TableHead, TableCell, TableBody, TableRow, TableContainer, Paper, Backdrop, CircularProgress } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Pdf from 'react-to-pdf';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const { PRIVATE_ROUTE } = API_ROUTES;

const ManagersideQuoteView = () => {
    const ref = React.createRef();
    const navigate = useNavigate();
    const { id } = useParams();
    const { authAxios } = useContext(FetchContext);
    const [value, setValue] = React.useState(0);
    const [quoteData, setquoteData] = React.useState();
    const [loading, setLoading] = React.useState(true);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getquotedata = useCallback(async () => {
        const { data } = await authAxios.get(
            `${PRIVATE_ROUTE.GET_QUOTE_BY_ID}/${id}`
        );
        setquoteData(data?.data[0]);
        setLoading(false);
    }, [authAxios, id]);

    useEffect(() => {
        getquotedata();
    }, []);

    const handleDelete = async () => {
        if (confirm("Are you sure want to delete?") === true) {
            await authAxios.post(`${PRIVATE_ROUTE?.DELETE_QUOTE}${quoteData._id}`);
            navigate('/manager/quote/list')
        }
    }

    const handleEdit = () => {
        navigate(`/manager/quote/update/${quoteData._id}`);
    }

    return (
        <>
            <Container maxWidth="xl">
                <Typography variant="h4" component="h2">
                Quote View
                </Typography>
                <Box sx={{ marginTop: "20px" }}>
                    <Grid container colSpacing={5} direction={"row"} spacing={{ xs: 2, sm: 2, md: 5 }}>
                        <Grid md={6} xs={12} item>
                            <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Grid xs={12} md={3} item mt={2} sx={{ maxWidth: 'auto' }}>
                                    <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => handleEdit()}>
                                        Edit
                                    </Button>
                                </Grid>

                                <Grid xs={12} md={4} sx={{ maxWidth: 'auto' }} item>
                                    <a href={`${process.env.REACT_APP_ASSET_URL}/quote/export/quote-${quoteData?.number.toString().padStart(6,'0')}.pdf`} target="_blank" rel="noreferrer" download style={{ textDecoration: "none" }}>
                                        <Button variant="contained" sx={{ width: 'auto', padding: '7px' }} color="primary" startIcon={<FileDownloadIcon />}>
                                        Export To PDF
                                    </Button> </a>
                                </Grid>

                                <Grid xs={12} md={5} item mt={2} sx={{ maxWidth: 'auto', marginBlock: 'auto' }}>
                                    <Button variant="contained" color="primary" startIcon={<DeleteIcon />} onClick={() => handleDelete()}>
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid md={6} item>{''}</Grid>
                    </Grid>
                </Box>
                <Box marginTop={'30px'}>
                    <Typography variant="h6" color="initial">
                        Quote No -  {quoteData?.number_str}
                    </Typography>
                    <Typography mt={'30px'} variant="h6" color="initial">
                        Billing Line Items
                    </Typography>
                    <Card style={{ marginTop: '30px' }}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                    <Tab label="Labour Cost Line Items" {...a11yProps(0)} />
                                    <Tab label="Equipment and Materials Line Items" {...a11yProps(1)} />
                                    <Tab label="Fixed Price Cost Line Items" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                                    Labour Cost Line Items
                                </Typography>
                                {quoteData?.billable_line_items?.labourItem?.map((item, i) => (
                                    <Grid key={i}>
                                        <Grid container>
                                            <Grid xs={12} md={6} sx={{ marginLeft: 3 }} container>
                                                <Grid sx={{ marginTop: '10px' }} xs={3} md={2} item>
                                                    <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                        Cost Item :
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: '10px' }} xs={9} md={4} item>
                                                    <Typography variant="body1" color="initial">
                                                        {item.costItem}
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid sx={{ marginTop: "10px" }} xs={3} md={1} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Name :
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} xs={9} md={8} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.name}
                                                </Typography>
                                            </Grid> */}
                                                <Grid sx={{ marginTop: '10px' }} xs={3} md={2} item>
                                                    <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                        Unit :
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: '10px' }} xs={9} md={4} item>
                                                    <Typography variant="body1" color="initial">
                                                        {item.unit}
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: '10px' }} xs={3} md={2} item>
                                                    <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                        Unit Cost :
                                                    </Typography>
                                                </Grid>
                                                <Grid sx={{ marginTop: '10px' }} xs={9} md={4} item>
                                                    <Typography variant="body1" color="initial">
                                                        {item.unitCost}
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid sx={{ marginTop: "10px" }} xs={3} md={1} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Hourly Cost :
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} xs={9} md={2} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.hourlyCost}
                                                </Typography>
                                            </Grid> */}
                                                <Grid sx={{ marginTop: '10px' }} xs={12} md={6} item>
                                                    <div style={{ display: 'flex' }}>
                                                        {item.text_exempt === true ? (
                                                            <CheckBoxOutlinedIcon sx={{ marginRight: 2 }} />
                                                        ) : (
                                                            <CheckBoxOutlineBlankOutlinedIcon sx={{ marginRight: 2 }} />
                                                        )}
                                                        <Typography mb={'5px'} variant="h6" color="initial">
                                                            Tax Exempt
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {quoteData?.billable_line_items.labourItem.length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                                    </Grid>
                                ))}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                                    Equipment and Materials Line Items
                                </Typography>
                                {quoteData?.billable_line_items?.materialItem?.map((item) => (
                                    <>
                                        <Grid xs={12} md={6} sx={{ marginLeft: 3 }} container>
                                            <Grid sx={{ marginTop: '10px' }} md={2} xs={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Cost Item :
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: '10px' }} md={4} xs={9} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.costItem}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid sx={{ marginTop: "10px" }} md={2} xs={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Name :
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={4} xs={9} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.name}
                                                </Typography>
                                            </Grid> */}
                                            <Grid sx={{ marginTop: '10px' }} md={2} xs={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Unit :
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: '10px' }} md={4} xs={9} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.unit}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: '10px' }} md={2} xs={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Unit Cost :
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: '10px' }} md={4} xs={9} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.unitCost}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: '10px' }} md={6} xs={12} item>
                                                <div style={{ display: 'flex' }}>
                                                    {item.text_exempt === true ? (
                                                        <CheckBoxOutlinedIcon sx={{ marginRight: 2 }} />
                                                    ) : (
                                                        <CheckBoxOutlineBlankOutlinedIcon sx={{ marginRight: 2 }} />
                                                    )}
                                                    <Typography mb={'5px'} variant="h6" color="initial">
                                                        Tax Exempt
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        {quoteData?.billable_line_items.materialItem.length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                                    </>
                                ))}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Typography mb={'5px'} variant={'subtitle1'} color="initial">
                                    Fixed Price Cost Line Items
                                </Typography>
                                {quoteData?.billable_line_items?.fixItem?.map((item) => (
                                    <>
                                        <Grid xs={12} md={6} sx={{ marginLeft: 3 }} container>
                                            <Grid sx={{ marginTop: '10px' }} md={2} xs={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Cost Item :
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: '10px' }} md={4} xs={9} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.costItem}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid sx={{ marginTop: "10px" }} md={2} xs={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Name :
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: "10px" }} md={4} xs={9} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.name}
                                                </Typography>
                                            </Grid> */}
                                            <Grid sx={{ marginTop: '10px' }} md={2} xs={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Unit :
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: '10px' }} md={4} xs={9} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.unit}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: '10px' }} md={2} xs={3} item>
                                                <Typography fontWeight={'bold'} variant="subtitle1" color="initial">
                                                    Unit Cost :
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: '10px' }} md={4} xs={9} item>
                                                <Typography variant="body1" color="initial">
                                                    {item.unitCost}
                                                </Typography>
                                            </Grid>
                                            <Grid sx={{ marginTop: '10px' }} md={6} xs={12} item>
                                                <div style={{ display: 'flex' }}>
                                                    {item.text_exempt === true ? (
                                                        <CheckBoxOutlinedIcon sx={{ marginRight: 2 }} />
                                                    ) : (
                                                        <CheckBoxOutlineBlankOutlinedIcon sx={{ marginRight: 2 }} />
                                                    )}
                                                    <Typography mb={'5px'} variant="h6" color="initial">
                                                        Tax Exempt
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        {quoteData?.billable_line_items.fixItem.length > 1 && <Divider sx={{ marginBlock: 3 }} />}
                                    </>
                                ))}
                            </TabPanel>
                        </Box>
                    </Card>
                    {loading && (
                        <div>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={loading}

                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </div>
                    )}
                    <div style={{ marginTop: "30px", display: 'flex' }}>
                        {quoteData?.active === true ? <CheckBoxOutlinedIcon sx={{ marginInline: 2 }} /> : <CheckBoxOutlineBlankOutlinedIcon sx={{ marginInline: 2 }} />}
                        <Typography mb={'5px'} variant="h6" color="initial">
                            Active
                        </Typography>
                    </div>
                </Box>
            </Container>
        </>
    )
}

export default ManagersideQuoteView