// component
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import WorkIcon from '@mui/icons-material/Work';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import PaidSharpIcon from '@mui/icons-material/PaidSharp';
import DirectionsCarSharpIcon from '@mui/icons-material/DirectionsCarSharp';
import CategorySharpIcon from '@mui/icons-material/CategorySharp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { getStorage } from 'src/utils/isAuthenticated';
import BusinessIcon from '@mui/icons-material/Business';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
// ----------------------------------------------------------------------





export const navConfig = () => {
  const navConfigAdmin = [
    {
      title: 'dashboard',
      path: '/dashboard/app',
      icon: <DashboardSharpIcon />,
    },
    {
      title: 'Job/Project',
      path: '/job/list',
      icon: <WorkIcon />,
  
    },
    {
      title: 'Rate Sheet',
      path: '/rate-sheet/list',
      icon: <LocalAtmIcon />,
    },
    {
      title: 'Client',
      path: '/client/list',
      icon: <PermContactCalendarIcon />,
    },
    {
      title: 'Quote',
      path: '/quote/list',
      icon: <RequestQuoteOutlinedIcon />,
  
    },
    // {
    //   title: 'Task',
    //   path: '/task/list',
    //   icon: <TaskAltIcon />,
  
    // },
    {
      title: 'Task',
      path: '#',
      icon: <TaskAltIcon />,
      child: [
        {
          title: 'Active Task',
          path: '/taskactive/list',
          icon: <TaskAltIcon />,
        },
        {
          title: 'Complete Task',
          path: '/taskcomplete/list',
          icon: <TaskAltIcon />,
        },
      ],
    },
     
    {
      title: 'Daily Work Report',
      path: '/dwr/list',
      icon: <WorkIcon />,
  
    },
    {
      title: 'Invoice',
      path: '/invoice/list',
      icon: <ReceiptIcon />,
  
      },
      {
        title: 'Report',
        path: '/report/list',
        icon: <ReceiptIcon />,
      },
    {
      title: 'Masters',
      path: '/master/dashboard',
      icon: <ManageAccountsIcon />,
      child: [
        {
          title: 'City',
          path: '/master/city/list',
          icon: <LocationOnSharpIcon />
        },
        {
          title: 'State',
          path: '/master/state/list',
          icon: <LocationOnSharpIcon />
        },
        {
          title: 'Office',
          path: '/master/office/list',
          icon: <LocationOnSharpIcon />
        },
        {
          title: 'Tax',
          path: '/master/tax/list',
          icon: <PaidSharpIcon />
        },
        {
          title: 'Fee',
          path: '/master/fee/list',
          icon: <PaidSharpIcon />
        },
        {
          title: 'Payment Term',
          path: '/master/paymentterm/list',
          icon: <WorkIcon />
        },
        {
          title: 'Client Type',
          path: '/master/clienttype/list',
          icon: <WorkIcon />
        },
        {
          title: 'Job Category',
          path: '/master/jobcategory/list',
          icon: <WorkIcon />
        },
        {
          title: 'Job Status',
          path: '/master/jobstatus/list',
          icon: <WorkIcon />
        },
        {
          title: 'Job Scope',
          path: '/master/jobscope/list',
          icon: <WorkIcon />
        },
        {
          title: 'Cost Item',
          path: '/master/costitem/list',
          icon: <CategorySharpIcon />
        },
        {
          title: 'Vehicle',
          path: '/master/vehicle/list',
          icon: <DirectionsCarSharpIcon />
        }
      ]
    },
    {
      title: 'user',
      path: '/user/list',
      icon: <PersonIcon />,
    },
    // {
    //   title: 'Invoice',
    //   path: '/invoice/list',
    //   icon: <WorkIcon/>,
  
    // },
  
  ]
  const navConfigManager = [
    {
      title: 'Job/Project',
      path: '/manager/job/list',
      icon: <WorkIcon />,
    },
    {
      title: 'Client',
      path: '/manager/client/list',
      icon: <PermContactCalendarIcon />,
    },
   
    /* {
      title: 'Task',
      path: '/manager/task/list',
      icon: <WorkIcon />,
    }, */
    {
      title: 'Quote',
      path: '/manager/quote/list',
      icon: <RequestQuoteOutlinedIcon />,
    },
    {
      title: 'Task',
      path: '#',
      icon: <WorkIcon />,
      child: [
        {
          title: 'Active Task',
          path: '/manager/taskactive/list',
          icon: <WorkIcon />
        },
        {
          title: 'Complete Task',
          path: '/manager/taskcomplete/list',
          icon: <WorkIcon />
        },
      ]
    },
    
    // {
    //   title: 'Schedule',
    //   path: '/manager/shedule',
    //   icon: <WorkIcon />,
    // },
    {
      title: 'Daily Work Report',
      path: '#',
      icon: <WorkIcon />,
      child: [
        {
          title: 'Staff Report',
          path: '/manager/dwrstaff/list',
          icon: <LocationOnSharpIcon />
        },
        {
          title: 'Work Report',
          path: '/manager/dwr/list',
          icon: <LocationOnSharpIcon />
        },
      ]
    }
  ]
  const navConfigEmployee = [
    // {
    //   title: 'Task',
    //   path: '/staff/task/list',
    //   icon: <TaskAltIcon />,
    // },
    // {
    //   title: 'Schedule',
    //   path: '/staff/shedule',
    //   icon: <TaskAltIcon />,
    // },
    {
      title: 'Daily Work Report',
      path: '/staff/dwr/list',
      icon: <TaskAltIcon />,
    },
  ];
  return({admin:navConfigAdmin,employee:navConfigEmployee,manager:navConfigManager})
}
