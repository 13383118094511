
import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  ThemeProvider, createTheme, Box, CircularProgress,Backdrop,
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import Scrollbar from "src/components/scrollbar";
import Iconify from '../../../iconify';
import download from 'downloadjs'
// mock
const { PRIVATE_ROUTE } = API_ROUTES;

export default function InvoiceList() {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(null);
  const { authAxios } = useContext(FetchContext);
  const [showloading, setshowLoading] = useState(false);

  const navigate = useNavigate();
  
  const handleRowDelete = async (id) => {
    setshowLoading(true);
    await authAxios.get(`${PRIVATE_ROUTE?.GENERATE_TOKEN}/${id}`,{responseType: 'blob'})
    .then((response) => {
      const content = response.headers['content-type'];
      download(response.data, id+".pdf", content);
      setshowLoading(false);
    });
    getInvoicedata("")
  }

  const handlecreateform = () => {
    navigate('/invoice/create');
  }
  
  const getInvoicedata = (query) => {
    let isMounted = true;
    const getInvoices = async () => {
      const { data } = await authAxios.get(`${PRIVATE_ROUTE?.GET_INVOICE}?page=${page + 1}&per_page=${rowsPerPage}&search=${query}`);
      if (isMounted) {
        setInvoices(data?.data?.map((item) => {
          const task_name = item.task_details.map((taskdetails) => taskdetails.number_str);
          return (
            {
              _id: item.qb_invoice_id,
              generated_date: item.generated_date,
              invoice_number: item?.number?.toString().padStart(6,'0'),
              client_number: <Link to={`/client/view/${item.client_id._id}`}><b>{item.client_id.number_str}</b></Link>,
              tasks: task_name.join(', '),
            })
        }));
        setCount(data.total);
        setLoading(false);
      }
    };

    getInvoices().catch((err) => {
      if (!isMounted) return;
      console.error("failed to fetch data", err);
      setLoading(false);
    });
    return () => {
      isMounted = false;
    };
  }

  useEffect(() => {
    getInvoicedata("")
  }, [rowsPerPage, page]);

  const columns = [
    {
      name: "_id",
      label: "Id",
      options: {
        draggable: true,
        sort: false,
        filter: false,
        display: false
      }
    },
    {
      name: "",
      label: "",
      options: {
        draggable: true,
        sort: false,
        filter: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
            <IconButton color="inherit" onClick={(e) => handleRowDelete(tableMeta.rowData[0])}>
              <Iconify width={15} icon={'eva:download-outline'} />
            </IconButton>
            </>
          );
        },
      }
    },
    {
      name: "invoice_number",
      label: "Invoice",
      options: {
        draggable: false,
         sort:false,
        filter: true,
        display: true,
      }
    },
    {
      name: "client_number",
      label: "Client",
      options: {
        draggable: false,
        sort:false,
        filter: true,
        display: true,
      }
    },
    {
      name: "tasks",
      label: "Tasks",
      options: {
        draggable: false,
       sort:false,
        filter: true,
        display: true,
      }
    },    {
      name: "generated_date",
      label: "Date Generated",
      options: {
        draggable: false,
        sort:false,
        filter: true,
        display: true,
      }
    },
  ];


  const options = {
    selectToolbarPlacement: 'none',
    tableId: "HelloWorld",
    rowHover: false,
    jumpToPage: true,
    responsive: "standerd",
    filters: true,
    print: true,
    fixedHeader: true,
    count: count,
    selectableRows: false,
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        style: {
          backgroundColor: rowIndex % 2 === 0 ? 'rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)' : 'white',

        },
      };
    },
    setTableProps: () => {
      return {
        padding: 'default',
        border: "1px solid rgba(101, 200, 208,0.15) 200, 208,0.15) 200, 208,0.15)",
        size: 'small',
      };
    },
    onChangeRowsPerPage: (numberOfRows) => {
      setRowsPerPage(numberOfRows);
    },
    rowsPerPage: rowsPerPage,
    serverSide: true,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setPage(tableState.page);
      }
    }
  };  

  return (
    <>
      <Grid sx={{ maxWidth: 1500, margin: "auto" }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Invoice
          </Typography>
          <Button variant="contained" onClick={handlecreateform} startIcon={<Iconify icon="eva:plus-fill" />}>
            New Invoice
          </Button>
        </Stack>
        <Grid>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <MUIDataTable
                  options={options}
                  columns={columns}
                  data={invoices}
                />
                {showloading ? (
                  <div>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showloading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </div>
                ) : (
                  ''
                )}
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {!invoices && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
        </Grid>
      </Grid>
    </>
  );
}
