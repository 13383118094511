import { Suspense, useContext } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import ClientList from './components/AdminsideComponent/Client/Clientview/ClientList';
import ClientForm from './components/AdminsideComponent/Client/ClientCreate/index';
import Joblist from './components/AdminsideComponent/Job/Jobview/Joblist';
import JobForm from './components/AdminsideComponent/Job/JobCreate';
import TaskForm from './components/AdminsideComponent/Task/TaskCreate';
import LoadingScreen from './components/LoadingScreen';
import PrivateOutlate from './components/Routes/PrivateOutlate';
import { isLoginStorage } from './utils/isAuthenticated';
import RateSheetForm from './components/AdminsideComponent/RateSheet/RateSheetCreate';
import ServiceList from './components/AdminsideComponent/Service/Serviceview/ServiceList';
import ServiceForm from './components/AdminsideComponent/Service/ServiceCreate';
import UserForm from './components/AdminsideComponent/User/UserCreate';
import UserList from './components/AdminsideComponent/User/Userview/UserList';
import CityList from './components/AdminsideComponent/Master/City/Cityview/CityList';
import CityForm from './components/AdminsideComponent/Master/City/CityCreate';
import TaxList from './components/AdminsideComponent/Master/Tax/Taxview/TaxList';
import TaxForm from './components/AdminsideComponent/Master/Tax/TaxCreate';
import JobCategoryForm from './components/AdminsideComponent/Master/JobCategory/JobCategoryCreate';
import JobCategoryList from './components/AdminsideComponent/Master/JobCategory/jobCategoryview/JobCategoryList';
import JobScopeList from './components/AdminsideComponent/Master/JobScope/jobScopeview/JobScopeList';
import JobScopeForm from './components/AdminsideComponent/Master/JobScope/JobScopeCreate';
import CostItemList from './components/AdminsideComponent/Master/CostItem/CostItemview/CostItemList';
import CostItemForm from './components/AdminsideComponent/Master/CostItem/CostItemCreate';
import VehicleList from './components/AdminsideComponent/Master/Vehicle/Vehicleview/VehicleList';
import VehicleForm from './components/AdminsideComponent/Master/Vehicle/VehicleCreate';
import RatesheetList from './components/AdminsideComponent/RateSheet/RateSheetview/RateSheetList';
import ClientView from './components/AdminsideComponent/Client/Clientview/ClientView';
import JobView from './components/AdminsideComponent/Job/Jobview/JobView';
import RateSheetView from './components/AdminsideComponent/RateSheet/RateSheetview/RateSheetView';
import InvoiceList from './components/AdminsideComponent/invoice/Invoiceview/InvoiceList';
import InvoiceForm from './components/AdminsideComponent/invoice/InvoiceCreate';
import InvoiceView from './components/AdminsideComponent/invoice/Invoiceview/InvoiceView';
import DailyWorkReportForm from './components/AdminsideComponent/Daily Work Report/DailyWorkReportCreate';
import DailyWorkReportView from './components/AdminsideComponent/Daily Work Report/DailyWorkReportview/DwrView';
import DailyWorkReportList from './components/AdminsideComponent/Daily Work Report/DailyWorkReportview/AdminDailyWorkReportlist';
import TaskView from './components/AdminsideComponent/Task/Taskview/TaskView';
// import ManagerJobForm from './components/ManagersideComponent/Job/ManagerJobCreate';
import ManagerJoblist from './components/ManagersideComponent/Job/ManagerJobview/ManagerJoblist';
import ManagerJobView from './components/ManagersideComponent/Job/ManagerJobview/ManagerJobView';
import DwrTaskCalenderView from './components/AdminsideComponent/Daily Work Report/DailyWorkReportview/DwrTaskCalanderView';
import ManagerDailyWorkReportlist from './components/ManagersideComponent/Daily Work Report/DailyWorkReportview/ManagerDailyWorkReportlist';
import ManagerDailyWorkReportView from './components/ManagersideComponent/Daily Work Report/DailyWorkReportview/ManagerDailyWorkReportView';
import ManagerDailyWorkReportCreateForm from './components/ManagersideComponent/Daily Work Report/DailyWorkReportCreate/ManagerDailyWorkReportCreateForm';
import StaffTaskList from './components/StaffsideComponent/Task/EmployeeTaskview/StaffTaskList';
import StaffTaskCalanderView from './components/StaffsideComponent/Task/EmployeeTaskview/StaffTaskCalanderView';
import StaffTaskView from './components/StaffsideComponent/Task/EmployeeTaskview/StaffTaskView';
import StaffTaskCreateForm from './components/StaffsideComponent/Task/EmployeeTaskCreate/StaffTaskCreateForm';
import TaskList from './components/AdminsideComponent/Task/Taskview/Tasklist';
import ResetPass from './pages/ResetPass';
import Password from './pages/Password';
import StateList from './components/AdminsideComponent/Master/State/Stateview/StateList';
import StateForm from './components/AdminsideComponent/Master/State/StateCreate';
import OfficeList from './components/AdminsideComponent/Master/Office/Officeview/OfficeList';
import OfficeForm from './components/AdminsideComponent/Master/Office/OfficeCreate';
import ManagersideTasklist from './components/ManagersideComponent/Task/Taskview/ManagersideTasklist';
import ManagersideJobForm from './components/ManagersideComponent/Task/TaskCreate';
import ManagersideTaskView from './components/ManagersideComponent/Task/Taskview/ManagersideTaskView';
import ManagersideTaskCalanderView from './components/ManagersideComponent/Task/Taskview/ManagersideTaskCalanderView';
import FeeList from './components/AdminsideComponent/Master/Fee/Feeview/FeeList';
import FeeForm from './components/AdminsideComponent/Master/Fee/FeeCreate';
import JobStatusList from './components/AdminsideComponent/Master/jobStatus/JobStatusView/JobStatusList';
import JobstatusForm from './components/AdminsideComponent/Master/jobStatus/JobStatusCreate';
import PaymentTermList from './components/AdminsideComponent/Master/PaymentTerm/PaymentTermView/PaymentTermList';
import PaymentTermForm from './components/AdminsideComponent/Master/PaymentTerm/PaymentTermCreate';
import ClientTypeList from './components/AdminsideComponent/Master/ClientType/ClientTypeView/ClientTypeList';
import ClientTypeForm from './components/AdminsideComponent/Master/ClientType/ClientTypeCreate';
import StaffDwrlist from './components/StaffsideComponent/StaffDWR/StaffDwrview/StaffDwrlist';
import StaffDwrForm from './components/StaffsideComponent/StaffDWR/StaffDwrCreate/StaffDwrForm';
import StaffDwrView from './components/StaffsideComponent/StaffDWR/StaffDwrview/StaffDwrView';
import StaffDwrCreateForm from './components/StaffsideComponent/StaffDWR/StaffDwrCreate';
import ManagerDailyWorkReportForm from './components/ManagersideComponent/Daily Work Report/DailyWorkReportCreate';
import StaffDailyWorkReportList from './components/ManagersideComponent/DWRs Management of Staff/DailyWorkReportview/StaffDailyWorkReportlist';
import AdminDailyWorkReportlist from './components/AdminsideComponent/Daily Work Report/DailyWorkReportview/AdminDailyWorkReportlist';
import StaffDailyWorkReportView from './components/ManagersideComponent/DWRs Management of Staff/DailyWorkReportview/StaffDailyWorkReportView';
import StaffJobForm from './components/StaffsideComponent/Task/EmployeeTaskCreate';
import DwrView from './components/AdminsideComponent/Daily Work Report/DailyWorkReportview/DwrView';
import ProfilePage from './pages/ProfilePage';
import SettingPage from './pages/SettingPage';
import Shedule from './components/StaffsideComponent/Shedule/EmployeeTaskview/Shedule';
import ManagersideShedule from './components/ManagersideComponent/Shedule/Taskview/ManagersideShedule';
import { AuthContext } from './context/AuthContext';
import QuoteList from './components/AdminsideComponent/Quote/QuoteView/QuoteList';
import QuoteView from './components/AdminsideComponent/Quote/QuoteView/QuoteView';
import QuoteForm from './components/AdminsideComponent/Quote/QuoteCreate';
import ManagersideQuoteList from './components/ManagersideComponent/Quote/QuoteView/QuoteList';
import ManagersideQuoteForm from './components/ManagersideComponent/Quote/QuoteCreate';
import ManagersideQuoteView from './components/ManagersideComponent/Quote/QuoteView/QuoteView';
import ManagersideTasklistActive from "./components/ManagersideComponent/Task/Taskview/ManagersideTasklistActive";
import ManagersideTasklistComplete from "./components/ManagersideComponent/Task/Taskview/ManagersideTasklistComplete";
import ReportList from './components/AdminsideComponent/report/Reportview/ReportList';
import ClientManagerList from './components/ManagersideComponent/Client/MangerClientview/ClientList';
import ClientManagerForm from './components/ManagersideComponent/Client/MangerClientCreate';
import ClientManageView from './components/ManagersideComponent/Client/MangerClientview/ClientView';
import ManagerJobForm from './components/ManagersideComponent/Job/ManagerJobview/JobCreate';
import AdminsideTasklistActive from './components/AdminsideComponent/Task/Taskview/AdminsideTasklistActive';
import AdminsideTasklistComplete from './components/AdminsideComponent/Task/Taskview/AdminsideTasklistComplete';

// ----------------------------------------------------------------------
const Loadable = (Component) =>
  function (props) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    const isDashboard = pathname.includes('/dashboard');

    return (
      <Suspense
        fallback={
          <LoadingScreen
            sx={{
              ...(!isDashboard && {
                top: 0,
                left: 0,
                width: 1,
                zIndex: 9999,
                position: 'fixed'
              })
            }}
          />
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
export default function Router() {
  const routes = useRoutes([
    {
      path: '/login',
      element: isLoginStorage() ? <Navigate to="/dashboard/app" /> : <LoginPage />
    },
    {
      path: '/reset-password',
      element: <ResetPass  /> 
    },
    {
      path: '/confirm-password/:token',
      element: <Password  /> 
    },
    {
      path: '/dashboard',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
      ],
    },
    {
      path: '/profile',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { element: <Navigate to="/profile/edit" />, index: true },
        { path: 'edit', element: <ProfilePage /> },
      ],
    },
    {
      path: '/settings',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { element: <Navigate to="/settings/edit/:id" />, index: true },
        { path: 'edit', element: <SettingPage /> },
      ],
    },
    {
      path: '/master',
      element: (<PrivateOutlate> 
        <DashboardLayout />
        </PrivateOutlate>),
      children: [
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'city/list', element: <CityList /> },
        { path: 'city/update/:id', element: <CityForm /> },
        { path: 'city/create', element: <CityForm /> },
        { path: 'state/list', element: <StateList /> },
        { path: 'state/update/:id', element: <StateForm /> },
        { path: 'state/create', element: <StateForm /> },
        { path: 'office/list', element: <OfficeList /> },
        { path: 'office/update/:id', element: <OfficeForm /> },
        { path: 'office/create', element: <OfficeForm /> },
        { path: 'tax/list', element: <TaxList /> },
        { path: 'tax/update/:id', element: <TaxForm /> },
        { path: 'tax/create', element: <TaxForm /> },
        { path: 'fee/list', element: <FeeList /> },
        { path: 'fee/update/:id', element: <FeeForm /> },
        { path: 'fee/create', element: <FeeForm /> },
        { path: 'clienttype/list', element: <ClientTypeList /> },
        { path: 'clienttype/update/:id', element: <ClientTypeForm /> },
        { path: 'clienttype/create', element: <ClientTypeForm /> },
        { path: 'paymentterm/list', element: < PaymentTermList /> },
        { path: 'paymentterm/update/:id', element: < PaymentTermForm /> },
        { path: 'paymentterm/create', element: < PaymentTermForm /> },
        { path: 'jobstatus/list', element: <JobStatusList /> },
        { path: 'jobstatus/update/:id', element: <JobstatusForm /> },
        { path: 'jobstatus/create', element: <JobstatusForm /> },
        { path: 'jobcategory/list', element: <JobCategoryList /> },
        { path: 'jobcategory/update/:id', element: <JobCategoryForm /> },
        { path: 'jobcategory/create', element: <JobCategoryForm /> },
        { path: 'jobscope/list', element: <JobScopeList /> },
        { path: 'jobscope/update/:id', element: <JobScopeForm /> },
        { path: 'jobscope/create', element: <JobScopeForm /> },
        { path: 'costitem/list', element: <CostItemList /> },
        { path: 'costitem/update/:id', element: <CostItemForm /> },
        { path: 'costitem/create', element: <CostItemForm /> },
        { path: 'vehicle/list', element: <VehicleList /> },
        { path: 'vehicle/update/:id', element: <VehicleForm /> },
        { path: 'vehicle/create', element: <VehicleForm /> },
        { path: 'services/create', element: <ServiceForm /> },
        { path: 'services/update/:id', element: <ServiceForm /> },
        { path: 'services/list', element: <ServiceList /> },
      ],
    },
    {
      path: '/client',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <ClientList /> },
        { path: 'update/:id', element: <ClientForm /> },
        { path: 'create', element: <ClientForm /> },
        { path: 'view/:id', element: <ClientView /> },
      ],
    },
    {
      path: '/task',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'create', element: <TaskForm /> },
        { path: 'update/:id', element: <TaskForm /> },
        { path: 'list', element: <TaskList /> },
        { path: 'view/:id', element: <TaskView /> },
      ],
    },
    {
      path: '/taskactive',
      element: (
        <PrivateOutlate>
          <DashboardLayout />
        </PrivateOutlate>
      ),
      children: [{ path: 'list', element: <AdminsideTasklistActive /> }],
    },
    {
      path: '/taskcomplete',
      element: (
        <PrivateOutlate>
          <DashboardLayout />
        </PrivateOutlate>
      ),
      children: [{ path: 'list', element: <AdminsideTasklistComplete /> }],
    },
    {
      path: '/quote',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'create', element: <QuoteForm /> },
        { path: 'update/:id', element: <QuoteForm /> },
        { path: 'list', element: <QuoteList /> },
        { path: 'view/:id', element: <QuoteView /> },
      ],
    },
    {
      path: '/rate-sheet',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <RatesheetList /> },
        { path: 'update/:id', element: <RateSheetForm /> },
        { path: 'create', element: <RateSheetForm /> },
        { path: 'view/:id', element: <RateSheetView /> },
      ],
    },
    {
      path: '/job',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <Joblist /> },
        { path: 'update/:id', element: <JobForm /> },
        { path: 'create', element: <JobForm /> },
        { path: 'view/:id', element: <JobView /> },
      ],
    },
    {
      path: 'manager/client',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <ClientManagerList /> },
        { path: 'update/:id', element: <ClientManagerForm /> },
        { path: 'create', element: <ClientManagerForm /> },
        { path: 'view/:id', element: <ClientManageView /> },
      ],
    },
    {
      path: 'manager/job',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <ManagerJoblist /> },
        { path: 'update/:id', element: <ManagerJobForm /> },
        { path: 'create', element: <ManagerJobForm /> },
        { path: 'view/:id', element: <ManagerJobView /> },
      ],
    },
    {
      path: 'manager/task',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <ManagersideTasklist /> },
        { path: 'update/:id', element: <ManagersideJobForm /> },
        { path: 'create', element: <ManagersideJobForm /> },
        { path: 'view/:id', element: <ManagersideTaskView /> },
        { path: 'calender', element: <ManagersideTaskCalanderView /> },
      ],
    },
     {
      path:'/manager/taskactive',
      element: (<PrivateOutlate>
        <DashboardLayout/>
        </PrivateOutlate>),
        children:[
         { path:'list',element:<ManagersideTasklistActive /> },
        ],
    },
    {
      path:'/manager/taskcomplete',
      element: (<PrivateOutlate>
        <DashboardLayout/>
        </PrivateOutlate>),
        children:[
         { path:'list',element:<ManagersideTasklistComplete /> },
        ],
    },
    {
      path: 'manager/quote',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <ManagersideQuoteList /> },
        { path: 'update/:id', element: <ManagersideQuoteForm /> },
        { path: 'create', element: <ManagersideQuoteForm /> },
        { path: 'view/:id', element: <ManagersideQuoteView /> },
      ],
    },
    {
      path: 'manager',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'shedule', element: <ManagersideShedule /> },
      ],
    },
    {
      path: '/dwr',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <AdminDailyWorkReportlist /> },
        { path: 'view/:id', element: <DwrView /> },
      ],
    },
    {
      path: '/manager/dwrstaff',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <StaffDailyWorkReportList /> },
        { path: 'view/:id', element: <StaffDailyWorkReportView /> },
      ],
    },
    {
      path: '/manager/dwr',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <ManagerDailyWorkReportlist /> },
        { path: 'update/:id', element: <ManagerDailyWorkReportForm /> },
        { path: 'create', element: <ManagerDailyWorkReportForm /> },
        { path: 'view/:id', element: <ManagerDailyWorkReportView /> },
      ],
    },
    {
      path: 'staff/task',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <StaffTaskList /> },
        { path: 'calender', element: <StaffTaskCalanderView /> },
        { path: 'update/:id', element: <StaffJobForm /> },
        { path: 'create', element: <StaffJobForm /> },
        { path: 'view/:id', element: <StaffTaskView /> },
      ],
    },
    {
      path: 'staff',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'shedule', element: <Shedule /> },
      ],
    },
    {
      path: 'staff/dwr',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <StaffDwrlist /> },
        { path: 'update/:id', element: <StaffDwrCreateForm /> },
        { path: 'create', element: <StaffDwrCreateForm /> },
        { path: 'view/:id', element: <StaffDwrView/> },
      ],
    },
    {
      path: '/user',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'create', element: <UserForm /> },
        { path: 'update/:id', element: <UserForm /> },
        { path: 'list', element: <UserList /> },
        // { path: 'service-form', element: <ServiceCreateForm /> },
      ],
    },
  
    {
      path: '/invoice',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <InvoiceList /> },
        { path: 'update/:id', element: <InvoiceForm /> },
        { path: 'create', element: <InvoiceForm /> },
        { path: 'view/:id', element: <InvoiceView /> },
      ],
    },

    {
      path: '/report',
      element: (<PrivateOutlate>
        <DashboardLayout />
      </PrivateOutlate>),
      children: [
        { path: 'list', element: <ReportList /> },
        
      ],
    },
    
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },

  ]);

  return routes;
}
