import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
  Stack,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Card,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  Chip,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AddIcon from '@mui/icons-material/Add';
import PropTypes, { element } from 'prop-types';
import CancelIcon from '@mui/icons-material/Cancel';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
// components
import { useNavigate } from 'react-router-dom/dist';
import { useFieldArray } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import ChipDropdown from 'src/components/customecomponent/ChipDropdown';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { FormElement } from '@progress/kendo-react-form';
import { createTheme } from '@mui/material';
import { FetchContext } from 'src/context/FetchContext';
import { API_ROUTES } from 'src/services/constant';

export default function TaskCreateForm({
  initialData,
  register,
  errors,
  watch,
  getValues,
  setValue,
  handleSubmit,
  onSubmit,
  params,
  editData,
  loading,
  control,
  multivaluePM,
  multivalueSM,
  setmultivaluePM,
  setmultivalueSM,
  attachments,
  setAttachments,
  quotealldata,
  setLabourCostData,
  labourCostData,
}) {
  console.log('quotealldata=====>', quotealldata);

  const { PRIVATE_ROUTE } = API_ROUTES;
  const { authAxios } = useContext(FetchContext);
  const navigate = useNavigate();
  const [condition, setCondition] = React.useState(params ? getValues('selectClient') : '');
  const [textAreaCount, settextAreaCount] = React.useState(0);
  const [valueBiling, setValueBiling] = React.useState(0);
  const [isValidatePM, setIsValidatePM] = React.useState(true);
  const [isValidateSM, setIsValidateSM] = React.useState(true);
  const [disable, setdisable] = React.useState(true);
  const [checkbox, setcheckbox] = React.useState();
  const [unitcost, setunitcost] = React.useState([]);
  const [estimatedhour, setestimatedhour] = React.useState([]);
  const [personName, setPersonName] = React.useState('');
  const [jobData, setjobData] = React.useState([]);
  const [defaultmultivaluePM, setdefaultmultivaluePM] = React.useState([]);
  const [defaultmultivalueSM, setdefaultmultivalueSM] = React.useState([]);
  const [clientlocation, setClientLocation] = useState([]);
  const { fields: labour, remove: removeLabour, insert: insertLabour } = useFieldArray({ control, name: 'labourItem' });
  const [selectRateStreet, setSelectRateStreet] = React.useState('1');
  const [TotalCostHour, setTotalCostHour] = useState(0);
  const [totalEstimatedHour, setTotalEstimatedHour] = useState(0);
  const {
    fields: material,
    remove: removeMaterial,
    insert: insertMaterial,
  } = useFieldArray({ control, name: 'materialItem' });

  const { fields: fix, remove: removeFix, insert: insertFix } = useFieldArray({ control, name: 'fixItem' });

  const {
    fields: additionalLabour,
    remove: removeAdditionalLabour,
    insert: insertAdditionalLabour,
  } = useFieldArray({ control, name: 'additionalLabourItem' });

  const {
    fields: additionalEquipment,
    remove: removeAdditionalEquipment,
    insert: insertAdditionalEquipment,
  } = useFieldArray({ control, name: 'additionalEquipmentItem' });
  useEffect(() => {
    if (selectRateStreet === 'SelectRateStreet') {
      console.log('SelectRateStreet--->', selectRateStreet);
      removeLabour();
      removeFix();
      removeMaterial();

      if (labourCostData?.labourItem?.length) {
        // removeMaterial([])
        let esthrarr = [];
        let costarr = [];
        labourCostData?.labourItem?.forEach((item, index) => {
          esthrarr.push(1);
          costarr.push(item.unitCost);
          insertLabour(index, {
            costItem: item.costItem || '',
            name: item.name || '',
            unit: item.unit || '',
            unitCost: item.unitCost || '',
            hourlyCost: item.hourlyCost || '',
            estimated_hour: 1,
            text_exempt: item.text_exempt || false,
          });
        });
        setestimatedhour(esthrarr);
        setunitcost(costarr);
      }
      if (labourCostData?.fixItem?.length) {
        // removeFix()
        // removeMaterial([])
        labourCostData?.fixItem?.forEach((item, index) => {
          insertFix(index, {
            costItem: item.costItem || '',
            name: item.name || '',
            unit: item.unit || '',
            unitCost: item.unitCost || '',
            text_exempt: item.text_exempt || false,
          });
        });
      }
      if (labourCostData?.materialItem?.length) {
        // removeMaterial()
        // removeMaterial([])
        labourCostData?.materialItem?.forEach((item, index) => {
          console.log('item-->', item);

          insertMaterial(index, {
            costItem: item.costItem || '',
            name: item.name || '',
            unit: item.unit || '',
            unitCost: item.unitCost || '',
            text_exempt: item.text_exempt || false,
          });
        });
      }
    }
  }, [labourCostData, insertLabour, insertFix, insertMaterial]);
  const handleFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setValue(`attachments`, reader.result);
      setAttachments(reader.result);
    };
  };

  function handleviewimage(base64Url) {
    if (base64Url.includes('data')) {
      const win = window.open();
      win?.document.write(`<img src="${base64Url}" ></img>`);
    } else {
      const win = window.open();
      win?.document.write(`<img src="${process.env.REACT_APP_ASSET_URL}/task/attachments/${base64Url}" ></img>`);
    }
  }

  const handleDeleteImage = () => {
    setAttachments('');
    setValue(`attachment`, '');
  };

  const handleLabourDelete = (i) => {
    removeLabour(i, 1);
    const updatedItems = [...estimatedhour];
    updatedItems.splice(i, 1);
    const updatedCosts = [...unitcost];
    updatedCosts.splice(i, 1);
    setunitcost(updatedCosts);
    setestimatedhour(updatedItems);
  };

  const handleMaterialDelete = (i) => {
    removeMaterial(i, 1);
  };

  const handleFixDelete = (i) => {
    removeFix(i, 1);
  };

  const handleAddLabour = () => {
    insertLabour(labour.length, {
      costItem: '',
      name: '',
      unit: '',
      unitCost: '',
      hourlyCost: '',
      estimated_hour: '',
      text_exemptLabour: false,
    });
  };

  const handleAddMaterial = () => {
    insertMaterial(material.length, {
      costItem: '',
      name: '',
      unit: '',
      unitCost: '',
      text_exempt: false,
    });
  };

  const handleAddFix = () => {
    insertFix(fix.length, {
      costItem: '',
      name: '',
      unit: '',
      unitCost: '',

      text_exempt_Fix: false,
    });
  };

  const handleAdditionalLabourDelete = (index) => {
    removeAdditionalLabour(index);
  };

  const handleAdditionalEquipmentDelete = (index) => {
    removeAdditionalEquipment(index);
  };

  const handleAdditionalLabourButton = () => {
    insertAdditionalLabour(additionalLabour.length, {
      field_name_labour: '',
      field_value_labour: '',
    });
  };

  const handleAdditionalEquipmentButton = () => {
    insertAdditionalEquipment(additionalEquipment.length, {
      field_name_equipment: '',
      field_value_equipment: '',
    });
  };

  const handleChangeBilling = (event, newValue) => {
    setValueBiling(newValue);
  };

  const multiselectProjectManager = (event) => {
    setdefaultmultivaluePM([...event.value]);
    setValue('ProjectManager', [...event.value]);
  };

  // const multiselectStaffMember = (event) => {
  //   if (event.value.length === 0) {
  //     setIsValidateSM(false);
  //   } else {
  //     setIsValidateSM(true);
  //   }
  //   setdefaultmultivalueSM([...event.value]);
  //   setValue('staffMembers', [...event.value]);
  // };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const filterquotedata = quotealldata
    ?.filter((item, index) => item?.number.toString().padStart(6, '0') === watch('selectQuote'))
    ?.map((iteam) => iteam);

  const defaultManagerData = initialData.manager?.filter((managerObj) => {
    let arr = multivaluePM?.filter((detail) => detail === managerObj.id);
    return !(arr.length === 0);
  });

  const defaultStaffData = initialData.staff?.filter((staffObj) => {
    let arr = multivalueSM?.filter((detail) => detail === staffObj.id);
    return !(arr.length === 0);
  });
  async function quoteclientdata(clientid) {
    const quotdata = await getclientlocationdata(clientid);
    if (!params) {
      setValue('clientLocation', quotdata[0]?.name);
      setValue('clientLocation_id', quotdata[0]?._id);
    }
  }

  useEffect(() => {
    if (!editData && watch('selectQuote')) {
      console.log("getValues('selectClient_id')", getValues('selectClient_id'));
      console.log('filterquotedata[0]?.client_name', filterquotedata[0]?.client_name);

      quoteclientdata(filterquotedata[0]?.client_id?._id);
      // quoteclientdata(getValues('selectClient_id'));

      setValue('selectClient', filterquotedata[0]?.client_name);
      setValue('companyName', filterquotedata[0]?.client_name);
      setValue('selectJob', filterquotedata[0]?.job_number);
      setValue('selectRatesheet', filterquotedata[0]?.ratesheet_name);
      setValue('selectRatesheet_id', filterquotedata[0]?.ratesheet_id?._id);
      setValue('selectClient_id', filterquotedata[0]?.client_id?._id);
      setValue('selectJob_id', filterquotedata[0]?.job_id?._id);

      setValue('estimateHour', filterquotedata[0]?.total_estimated_hour);

      filterquotedata[0]?.billable_line_items?.labourItem?.map((item, i) => {
        setValue(`labourItem.${i}.costItem`, item.costItem);
        setValue(`labourItem.${i}.name`, item.name);
        setValue(`labourItem.${i}.unit`, item.unit);
        setValue(`labourItem.${i}.unitCost`, item.unitCost);
        setValue(`labourItem.${i}.hourlyCost`, item.hourlyCost);
        setValue(`labourItem.${i}.estimated_hour`, item.estimated_hour);
        setValue(`labourItem.${i}.text_exemptLabour`, item.text_exempt);
      });
      filterquotedata[0]?.billable_line_items?.fixItem?.map((item, i) => {
        setValue(`fixItem.${i}.costItem`, item.costItem);
        setValue(`fixItem.${i}.name`, item.name);
        setValue(`fixItem.${i}.unit`, item.unit);
        setValue(`fixItem.${i}.unitCost`, item.unitCost);
        setValue(`fixItem.${i}.text_exemptLabour`, item.text_exempt);
      });
      filterquotedata[0]?.billable_line_items?.materialItem?.map((item, i) => {
        setValue(`materialItem.${i}.costItem`, item.costItem);
        setValue(`materialItem.${i}.name`, item.name);
        setValue(`materialItem.${i}.unit`, item.unit);
        setValue(`materialItem.${i}.unitCost`, item.unitCost);
        setValue(`materialItem.${i}.text_exemptLabour`, item.text_exempt);
      });

      if (filterquotedata[0]?.project_manager) {
        const quoteBasedefaultManagerData = initialData.manager?.filter((managerObj) => {
          let arr = filterquotedata[0]?.project_manager?.filter((detail) => detail === managerObj.id);
          return !(arr.length === 0);
        });

        setdefaultmultivaluePM(quoteBasedefaultManagerData);
        setValue('ProjectManager', quoteBasedefaultManagerData);
      }
    }

    if (watch('selectQuote')) {
      setdisable(false);
    }
    if (filterquotedata[0]?.billable_line_items?.labourItem?.length !== 0 && watch('selectQuote')) {
      handleAddLabour();
      handleLabourDelete(labour.length);
    }
    if (filterquotedata[0]?.billable_line_items?.fixItem?.length !== 0 && watch('selectQuote')) {
      handleAddFix();
      handleFixDelete(fix.length);
    }
    if (filterquotedata[0]?.billable_line_items?.materialItem?.length !== 0 && watch('selectQuote')) {
      handleAddMaterial();
      handleMaterialDelete(material.length);
    }
  }, [watch('selectQuote')]);

  React.useEffect(() => {
    if (params && editData?.billing_line_items?.labour_item?.labour_cost_items?.length !== 0) {
      let esthrarr = [];
      let costarr = [];
      editData?.billing_line_items?.labour_item?.labour_cost_items?.map((element, i) => {
        esthrarr.push(element.estimated_hour);
        costarr.push(element.unitCost);

        handleAddLabour();
        handleLabourDelete(labour.length);
      });
      setestimatedhour(esthrarr);
      setunitcost(costarr);
    }
    if (params && editData?.billing_line_items?.labour_item?.additional_fields?.length !== 0) {
      editData?.billing_line_items?.labour_item?.additional_fields?.map((element, i) => {
        handleAdditionalLabourButton();
        handleAdditionalLabourDelete(additionalLabour.length);
      });
    }
    if (params && editData?.billing_line_items?.equipment_item?.equipment_cost_items?.length !== 0) {
      editData?.billing_line_items?.equipment_item?.equipment_cost_items?.map((element, i) => {
        handleAddMaterial();
        handleMaterialDelete(material.length);
      });
    }
    if (params && editData?.billing_line_items?.equipment_item?.additional_fields?.length !== 0) {
      editData?.billing_line_items?.equipment_item?.additional_fields?.map((element, i) => {
        handleAdditionalEquipmentButton();
        handleAdditionalEquipmentDelete(additionalEquipment.length);
      });
    }
    if (params && editData?.billing_line_items?.fixed_item?.length !== 0) {
      editData?.billing_line_items?.fixed_item?.map((element, i) => {
        handleAddFix();
        handleFixDelete(fix.length);
      });
    }
    if (defaultManagerData) {
      setdefaultmultivaluePM(defaultManagerData);
      setValue('ProjectManager', defaultManagerData);
    }
    if (defaultStaffData) {
      setdefaultmultivalueSM(defaultStaffData);
      setValue('staffMembers', defaultStaffData);
    }
    if (editData?.client_id?._id) {
      // const clientJobData = initialData?.jobinitial
      //   ?.filter((item) => item?.client_id === editData?.client_id?._id)
      //   ?.map((item, index) => {
      //     return item;
      //   });
      // getclientlocationdata(editData?.client_id?._id);
      // setjobData(clientJobData);
    }
  }, [editData]);

  const quotebasejobdata = initialData?.jobinitial
    ?.filter(
      (item) =>
        item.job_number ===
        initialData?.quoteinitial?.filter((iteam) => iteam._id === getValues('selectQuote_id')?.trim())[0]?.job_number
    )
    ?.map((jobItem) => ({ label: jobItem.job_number, id: jobItem._id }));
  useEffect(() => {
    if (watch('selectQuote') && !editData) {
      const esthrarr = filterquotedata[0]?.billable_line_items?.labourItem?.map((item) => item.estimated_hour) || [];
      const costarr = filterquotedata[0]?.billable_line_items?.labourItem?.map((item) => item.unitCost) || [];
      const totalCost = multiplyAndSum(costarr, esthrarr);
      const totalHours = esthrarr.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);

      setTotalCostHour(totalCost);
      setTotalEstimatedHour(totalHours);
      setValue('estimateHour', totalHours);
      setestimatedhour(esthrarr);
      setunitcost(costarr);
    }
  }, [watch('selectQuote')]);

  useEffect(() => {
    if (watch('selectQuote') && unitcost.length > 0 && estimatedhour.length > 0 && !editData) {
      const esthrarr = filterquotedata[0]?.billable_line_items?.labourItem?.map((item) => item.estimated_hour) || [];
      const costarr = filterquotedata[0]?.billable_line_items?.labourItem?.map((item) => item.unitCost) || [];

      if (esthrarr !== estimatedhour && costarr !== unitcost) {
        const totalCost = multiplyAndSum(unitcost, estimatedhour);
        const totalHours = estimatedhour.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
        setTotalCostHour(totalCost);
        setTotalEstimatedHour(totalHours);
        setValue('estimateHour', totalHours);
      } else if (esthrarr === estimatedhour && costarr !== unitcost) {
        const totalHours = estimatedhour.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
        setTotalEstimatedHour(totalHours);
        setValue('estimateHour', totalHours);
      } else if (esthrarr !== estimatedhour && costarr === unitcost) {
        const totalCost = multiplyAndSum(unitcost, estimatedhour);
        setTotalCostHour(totalCost);
      }
    } else {
      // If `selectQuote` is not selected, perform the default calculation
      const totalCost = multiplyAndSum(unitcost, estimatedhour);
      setTotalCostHour(totalCost);
      const totalHours = estimatedhour.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
      setTotalEstimatedHour(totalHours);
      setValue('estimateHour', totalHours);
    }
  }, [unitcost, estimatedhour, watch('selectQuote')]);

  const multiplyAndSum = (arr1, arr2) => {
    const multipliedValues = arr1.map((value, index) => {
      const parsedValue1 = value;
      const parsedValue2 = arr2[index] ?? 0;
      return parsedValue1 * parsedValue2;
    });

    return multipliedValues.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const calculateTotalCost = (index, newValue) => {
    setunitcost((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = newValue;
      return newArray;
    });
  };

  const calculateTotalHours = (index, newValue) => {
    setestimatedhour((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = newValue;
      return newArray;
    });
  };

  // const multiplyAndSum = (arr1, arr2) => {
  //   const multipliedValues = arr1.map((value, index) => {
  //     const parsedValue1 = value;
  //     const parsedValue2 = arr2[index] ?? 0;
  //     return parsedValue1 * parsedValue2;
  //   });

  //   const total = multipliedValues.reduce((accumulator, currentValue) => {
  //     return accumulator + currentValue;
  //   }, 0);

  //   return total;
  // };

  // const TotalCostHour = multiplyAndSum(unitcost, estimatedhour);

  // const calculateTotalCost = (index, newValue) => {
  //   setunitcost((prevArray) => {
  //     const newArray = [...prevArray];
  //     newArray[index] = newValue;
  //     return newArray;
  //   });
  // };

  // const calculateTotalHours = (index, newValue) => {
  //   setestimatedhour((prevArray) => {
  //     const newArray = [...prevArray];
  //     newArray[index] = newValue;
  //     return newArray;
  //   });
  // };

  // const totalEstimatedHour = estimatedhour.reduce((accumulator, currentValue) => {
  //   return accumulator + Number(currentValue);
  // }, 0);

  const theme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: { color: 'red' },
        },
      },
    },
  });
  // *************************************************************CLIENT ONCHANGE*********************************************************************************************
  const handleChangeField = async (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value?.split(',')[0] : value);
    setValue('selectClient', value?.split(',')[0]);
    setValue('selectClient_id', value?.split(',')[1].trim());
    setPersonName(getValues('selectClient'));
    getclientlocationdata(getValues('selectClient_id'));

    // const clientJobData = initialData?.jobinitial;
    // ?.filter((item) => item?.client_id === getValues('selectClient_id'))
    // ?.map((item, index) => {
    //   return item;
    // });

    // setjobData(clientJobData);
    setCondition(value?.split(',')[0]);
  };
  // ******************************************************************GET CLIENT DATA API ***********************
  async function getclientlocationdata(client_id) {
    if (client_id) {
      const clientLocationResponse = await authAxios.get(`${PRIVATE_ROUTE.GET_CLIENT_BY_ID}/${client_id}`);
      const clientLocationData = clientLocationResponse?.data?.data[0]?.locations;
      setClientLocation(clientLocationData);
      return clientLocationData;
    }
    console.log('empty client_id');
    return [];
  }
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName?.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  let clientState = 'selectClient';
  const stateData = clientState.split('.');
  let checked = 'checked';
  let errorMassage = false;
  if (!checked) {
    if (
      errors !== null &&
      errors[`${stateData[0]}`] &&
      errors[`${stateData[0]}`][`${stateData[1]}`] &&
      errors[`${stateData[0]}`][`${stateData[1]}`][`${stateData[2]}`]
    ) {
      errorMassage = true;
    }
  } else if (errors !== null && errors[`${stateData[0]}`]) {
    errorMassage = true;
  }

  return (
    <form autoComplete="off" noValidate onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} padding={3}>
        {/* Title */}
        {params ? (
          <>
            <Typography variant="h4" component="h2">
              Edit Task
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" component="h2">
              Create Task
            </Typography>
          </>
        )}
        <Grid>
          <Grid container spacing={5}>
            <Grid md={6} xs={12} item>
              <FormLabel>
                <>
                  Select Client<span style={{ color: 'red' }}>*</span>
                </>
              </FormLabel>
              <FormControl fullWidth>
                <Select
                  fullWidth
                  className={errorMassage ? 'error-input-border' : ''}
                  onChange={(e) => {
                    handleChangeField(e);
                  }}
                  value={getValues('selectClient') || ''}
                  defaultValue={getValues('selectClient') || ''}
                  rules={{ required: true }}
                  inputProps={
                    <>
                      <TextField
                        {...register('selectClient', {
                          required: '**Field is required',
                        })}
                        error={Boolean(errorMassage)}
                        helperText={errorMassage ? '*Field is required' : null}
                      />
                    </>
                  }
                  renderValue={(selected) => {
                    console.log('selected++++++', selected);

                    return (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.1 }}>
                        <Chip label={selected?.split(',')[0]} sx={{ backgroundColor: '#65C8D0', color: 'white' }} />
                      </Box>
                    );
                  }}
                >
                  {initialData?.client?.map((name) => {
                    return (
                      <MenuItem
                        key={name.id}
                        selected={personName === name.label}
                        value={`${name.label},${name.id}`}
                        style={getStyles(name.label, personName, theme)}
                      >
                        {name.label === '' ? 'no data found' : name.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText className="error_msg">{errorMassage ? '**Field is required ' : ''}</FormHelperText>
              </FormControl>
            </Grid>
            {condition === '' ? (
              ''
            ) : (
              <Grid md={6} item xs={12}>
                <FormLabel>
                  <>
                    Client Name<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
                <TextField
                  fullWidth
                  type="text"
                  name="companyName"
                  {...register('companyName', {
                    required: '**Field is required and needs to be a text',
                  })}
                  value={getValues('selectClient') || ''}
                  error={Boolean(errors.companyName)}
                  helperText={errors.companyName?.message}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            )}
          </Grid>
          {/* OR */}
          {condition === '' ? (
            <Grid container mt={-3} spacing={5}>
              <Grid md={6} xs={12} item>
                <Typography textAlign={'center'} variant="h4" color="initial">
                  OR
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          <Grid container mt={-1} spacing={5}>
            {getValues('selectClient') && !getValues('selectQuote') ? (
              <Grid item xs={12} md={6}>
                <FormLabel>
                  <>Status</>
                </FormLabel>
                <ChipDropdown
                  state={'Status'}
                  secondstate={'Status_id'}
                  setValue={setValue}
                  getValues={getValues}
                  data={initialData.status}
                  register={register}
                  validation={true}
                  errors={errors}
                  checked={'checked'}
                />
              </Grid>
            ) : (
              <Grid md={6} xs={12} item>
                <FormLabel>
                  <>Select Quote</>
                </FormLabel>

                <ChipDropdown
                  getValues={getValues}
                  state={'selectQuote'}
                  secondstate={'selectQuote_id'}
                  setValue={setValue}
                  data={initialData?.filterquote}
                  register={register}
                  setCondition={setCondition}
                  validation={false}
                  errors={null}
                  checked={'checked'}
                  client="quote"
                />
              </Grid>
            )}
            {condition === '' ? (
              ''
            ) : (
              <Grid md={6} xs={12} item>
                <FormLabel>
                  <>
                    Select Job<span style={{ color: 'red' }}>*</span>
                  </>
                </FormLabel>
                <ChipDropdown
                  getValues={getValues}
                  state={'selectJob'}
                  secondstate={'selectJob_id'}
                  setValue={setValue}
                  data={
                    getValues('selectQuote')
                      ? quotebasejobdata?.map((item) => {
                          return { label: item?.label, id: item.id };
                        })
                      : initialData?.jobinitial?.map((item) => {
                          return { label: item?.job_number, id: item._id };
                        })
                  }
                  // data={initialData?.jobinitial?.map((item) => {
                  //   return { label: item?.job_number, id: item._id };
                  // })}
                  register={register}
                  validation={true}
                  value={initialData?.job}
                  errors={errors}
                  checked={'checked'}
                />
              </Grid>
            )}
          </Grid>

          {condition === '' ? (
            <Grid container>
              <Grid md={6} item>
                <Button onClick={() => navigate('/quote/create')} variant="contained" sx={{ marginTop: '20px' }}>
                  Create Quote
                </Button>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container direction={'row'} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                {/* Project Manager */}
                <Grid xs={12} item md={6}>
                  <FormLabel>Project Manager</FormLabel>
                  <Grid xs={12} item md={6}>
                    <div className="col-12 col-md-6 example-col">
                      <FormElement>
                        <MultiSelect
                          className="custom_error_inputborder"
                          data={initialData.manager}
                          value={defaultmultivaluePM}
                          textField="label"
                          dataItemKey="id"
                          onChange={multiselectProjectManager}
                          // valid={isValidatePM ? true : false}
                          // required={true}
                          style={{
                            width: '200%',
                            height: '55px',
                            color: 'transparent',
                          }}
                        />
                        {isValidatePM ? null : (
                          <p className="k-Invalid-error MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained">
                            *Field is required
                          </p>
                        )}
                      </FormElement>
                    </div>
                  </Grid>
                </Grid>
                {/* Staff Member */}
                {/* <Grid md={6} item xs={12}>
                  <FormLabel>Staff Member</FormLabel>
                  <Grid xs={12} item md={6}>
                    <div className="col-12 col-md-6 example-col">
                      <FormElement>
                        <MultiSelect
                          className="custom_error_inputborder"
                          data={initialData.staff}
                          textField="label"
                          dataItemKey="id"
                          onChange={multiselectStaffMember}
                          value={defaultmultivalueSM}
                          valid={isValidateSM ? true : false}
                          required={true}
                          style={{
                            width: '200%',
                            height: '55px',
                            color: 'transparent',
                          }}
                        />
                        {isValidateSM ? null : (
                          <p className="k-Invalid-error MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained">
                            *Field is required
                          </p>
                        )}
                      </FormElement>
                    </div>
                  </Grid>
                </Grid> */}
                {/* Name */}
                {/* <Grid md={6} item xs={12}>
                  <FormLabel>
                    <>
                      Name<span style={{ color: 'red' }}>*</span>
                    </>
                  </FormLabel>
                  <TextField
                    fullWidth
                    type="text"
                    name="name"
                    {...register('name', {
                      required: '**Field is required and needs to be a text',
                    })}
                    error={Boolean(errors.name)}
                    helperText={errors.name?.message}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid> */}
                {/* Description */}
                <Grid md={6} item xs={12}>
                  <FormLabel>
                    <>
                      Description<span style={{ color: 'red' }}>*</span>
                    </>
                  </FormLabel>
                  <TextField
                    fullWidth
                    type="text"
                    name="description"
                    {...register('description', {
                      required: '**Field is required and needs to be a text',
                    })}
                    error={Boolean(errors.description)}
                    helperText={errors.description?.message}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                {/* Client Location */}
                <Grid md={6} item xs={12}>
                  <FormLabel>
                    <>Client Location</>
                  </FormLabel>
                  <ChipDropdown
                    state={'clientLocation'}
                    secondstate={'clientLocation_id'}
                    setValue={setValue}
                    getValues={getValues}
                    data={clientlocation.map((client) => ({
                      label: client.name,
                      id: client._id,
                    }))}
                    register={register}
                    validation={true}
                    errors={errors}
                    checked={'checked'}
                  />
                </Grid>

                {/* Office */}
                <Grid md={6} item xs={12}>
                  <FormLabel>
                    <>
                      Office<span style={{ color: 'red' }}>*</span>
                    </>
                  </FormLabel>
                  <ChipDropdown
                    state={'Office'}
                    secondstate={'Office_id'}
                    setValue={setValue}
                    getValues={getValues}
                    data={initialData.office}
                    register={register}
                    validation={true}
                    errors={errors}
                    checked={'checked'}
                  />
                </Grid>
                {/* Task Category */}
                <Grid item xs={12} md={6}>
                  <FormLabel>
                    <>Task Category</>
                  </FormLabel>
                  <ChipDropdown
                    state={'TaskCategory'}
                    secondstate={'TaskCategory_id'}
                    setValue={setValue}
                    getValues={getValues}
                    data={initialData.category}
                    register={register}
                    validation={true}
                    errors={errors}
                    checked={'checked'}
                  />
                </Grid>
                {/* Task Scope */}
                <Grid item xs={12} md={6}>
                  <FormLabel>
                    <>Task Scope</>
                  </FormLabel>
                  <ChipDropdown
                    state={'TaskScope'}
                    secondstate={'TaskScope_id'}
                    setValue={setValue}
                    getValues={getValues}
                    data={initialData.scope}
                    register={register}
                    validation={true}
                    errors={errors}
                    checked={'checked'}
                  />
                </Grid>
                {/* Estimated Hours */}
                <Grid md={6} item xs={12}>
                  <FormLabel>
                    <>Estimate Hours</>
                  </FormLabel>
                  <TextField
                    fullWidth
                    type="number"
                    step="0.01"
                    name="estimateHour"
                    value={totalEstimatedHour}
                    {...register(`estimateHour`, {
                      pattern: {
                        value: /^[0-9]/,
                        message: 'invalid input type',
                      },
                    })}
                    error={Boolean(errors.estimate_hour)}
                    helperText={errors.estimate_hour?.message}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {/* GL Code */}
                {/* <Grid item xs={12} md={6}>
                  <FormLabel>
                    <>
                      GL Code Prifix <span style={{ color: 'red' }}>*</span>
                    </>
                  </FormLabel>
                  <TextField
                    fullWidth
                    type="text"
                    name="glCode"
                    {...register('glCode', {
                      required: '*Field is required and needs to be a text',
                    })}
                    error={Boolean(errors.glCode)}
                    helperText={errors.glCode?.message}
                    inputProps={{ maxLength: 50 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid> */}
                {/* Ratesheet */}
                <Grid item xs={12} md={6}>
                  <FormLabel>
                    <>RateSheet</>
                  </FormLabel>
                  {/* <ChipDropdown
                    state={'selectRatesheet'}
                    secondstate={'selectRatesheet_id'}
                    setValue={setValue}
                    getValues={getValues}
                    data={initialData.ratesheet}
                    register={register}
                    validation={true}
                    errors={errors}
                    checked={'checked'}
                    selectRateStreet={selectRateStreet}
                    setSelectRateStreet={setSelectRateStreet}
                  /> */}
                  <ChipDropdown
                    state={'selectRatesheet'}
                    secondstate={'selectRatesheet_id'}
                    setValue={setValue}
                    getValues={getValues}
                    // data={ratesheetData?.map((item) => {
                    //   return { label: item?.name, id: item?._id };
                    // })}
                    data={initialData.ratesheet}
                    register={register}
                    validation={true}
                    errors={errors}
                    checked={'checked'}
                    rateSheetId="getRateSheetId"
                    setLabourCostData={setLabourCostData}
                    setSelectRateStreet={setSelectRateStreet}
                    selectRateStreet={selectRateStreet}

                    // onChange={handleRateSheetChange}  // Pass onChange prop
                  />
                </Grid>
                {getValues('selectQuote') ? (
                  <Grid item xs={12} md={6}>
                    <FormLabel>
                      <>Status</>
                    </FormLabel>
                    <ChipDropdown
                      state={'Status'}
                      secondstate={'Status_id'}
                      setValue={setValue}
                      getValues={getValues}
                      data={initialData.status}
                      register={register}
                      validation={true}
                      errors={errors}
                      checked={'checked'}
                    />
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>

              {/* Billing Line Items */}
              <Grid mt={3}>
                <Grid>
                  <Typography variant="h5" component="h2">
                    Billing Line Items
                  </Typography>
                </Grid>
                <Grid mt={2}>
                  <Card>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={valueBiling} onChange={handleChangeBilling} aria-label="basic tabs example">
                        <Tab label="Labour Costs" {...a11yProps(0)} />
                        <Tab label="Equipment and Materials" {...a11yProps(1)} />
                        <Tab label="Fixed Price" {...a11yProps(2)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={valueBiling} index={0}>
                      <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
                        Labour Costs
                      </Typography>
                      {labour?.map((item, i) => (
                        <>
                          <Grid key={i}>
                            <Grid sx={{ display: 'flex' }}>
                              <Grid sx={{ marginRight: '10px' }}>
                                <CancelIcon
                                  key={i}
                                  onClick={() => handleLabourDelete(i)}
                                  sx={{ fontSize: '42px', color: 'red' }}
                                  startIcon={<Iconify icon="eva:plus-fill" />}
                                >
                                  {''}
                                </CancelIcon>
                              </Grid>
                              <Divider orientation="vertical" variant="middle" flexItem />
                              <Grid sx={{ marginLeft: '20px' }} direction={'row'} container mt={4} spacing={5}>
                                <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                  <Grid item xs={12} md={6}>
                                    <FormLabel>
                                      <>
                                        Cost Item<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <ChipDropdown
                                      state={`labourItem.${i}.costItem`}
                                      setValue={setValue}
                                      getValues={getValues}
                                      data={initialData.labourCostItem}
                                      startIcon={<FmdGoodIcon />}
                                      register={register}
                                      validation={true}
                                      errors={errors}
                                    />
                                  </Grid>
                                  {/* <Grid item xs={12} md={6}>
                                    <FormLabel>
                                      <>
                                        Name<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type="text"
                                      {...register(`labourItem.${i}.name`, {
                                        required: '*Field is required and needs to be a text',
                                      })}
                                      error={Boolean(errors.labourItem ? errors.labourItem[i].name : null)}
                                      helperText={
                                        Boolean(errors.labourItem ? errors.labourItem[i].name : false)
                                          ? '*Field is required and needs to be a text'
                                          : null
                                      }
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid> */}
                                  <Grid item xs={12} md={6}>
                                    <FormLabel>
                                      <>
                                        Unit<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type="text"
                                      {...register(`labourItem.${i}.unit`, {
                                        required: '*Field is required and needs to be a text',
                                      })}
                                      error={Boolean(errors.labourItem ? errors.labourItem[i].unit : null)}
                                      helperText={
                                        Boolean(errors.labourItem ? errors.labourItem[i].unit : false)
                                          ? '*Field is required and needs to be a text'
                                          : null
                                      }
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <FormLabel>
                                      <>
                                        Unit Cost<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      step="0.01"
                                      {...register(`labourItem.${i}.unitCost`, {
                                        required: '*Field is required and needs to be a number',
                                      })}
                                      onChange={(e) => {
                                        calculateTotalCost(i, e.target.value);
                                        setValue(`labourItem.${i}.unitCost`, e.target.value);
                                      }}
                                      // value={watch(`labourItem.${i}.unitCost`)}
                                      min={0}
                                      error={Boolean(errors.labourItem ? errors.labourItem[i]?.unitCost : null)}
                                      helperText={errors.labourItem ? errors.labourItem[i]?.unitCost?.message : null}
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid>
                                  {/* <Grid item xs={12} md={6}>
                                    <FormLabel>
                                      <>Hourly Cost</>
                                    </FormLabel>
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type="number"
                                      {...register(`labourItem.${i}.hourlyCost`, {
                                        required: '*Field is required and needs to be a number',
                                      })}
                                      error={Boolean(errors.labourItem ? errors.labourItem[i].hourlyCost : null)}
                                      helperText={
                                        Boolean(errors.labourItem ? errors.labourItem[i].hourlyCost : false)
                                          ? '*Field is required and needs to be a number'
                                          : null
                                      }
                                      InputLabelProps={{ shrink: true }}
                                    />
                                  </Grid> */}
                                  <Grid item xs={12} md={6}>
                                    <FormLabel>
                                      <>Estimated Hour</>
                                    </FormLabel>
                                    <TextField
                                      fullWidth
                                      type="number"
                                      step="0.01"
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e, val) => {
                                        calculateTotalHours(i, e.target.value);
                                        setValue(`labourItem.${i}.estimated_hour`, e.target.value);
                                      }}
                                      value={watch(`labourItem.${i}.estimated_hour`)}
                                      error={Boolean(errors.labourItem ? errors.labourItem[i]?.estimated_hour : null)}
                                      helperText={
                                        errors.labourItem ? errors.labourItem[i]?.estimated_hour?.message : null
                                      }
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid mt={2} container>
                                  <Grid item>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            defaultChecked={
                                              params && getValues(`labourItem.${i}.text_exemptLabour`) === true
                                                ? true
                                                : false
                                            }
                                            checked={getValues(`labourItem.${i}.text_exemptLabour`) ? true : false}
                                            onChange={(e, val) => {
                                              setValue(`labourItem.${i}.text_exemptLabour`, val);
                                            }}
                                          />
                                        }
                                        label="Text Exempt"
                                      />
                                    </FormGroup>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
                        </>
                      ))}
                      <Grid>
                        <Button
                          variant="contained"
                          sx={{ marginTop: '0px' }}
                          onClick={handleAddLabour}
                          startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                        >
                          Add Labour Cost Item
                        </Button>
                      </Grid>
                      <Grid mt={3}>
                        <Grid>
                          <Typography variant="h6"> Additional Fields</Typography>
                        </Grid>
                        {additionalLabour.map((element, index) => (
                          <>
                            <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                              <Grid sx={{ marginRight: '15px' }}>
                                <HighlightOffIcon
                                  variant="contained"
                                  onClick={() => handleAdditionalLabourDelete(index)}
                                  sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }}
                                  startIcon={<Iconify icon="eva:plus-fill" />}
                                >
                                  {''}
                                </HighlightOffIcon>
                              </Grid>
                              <Divider orientation="vertical" variant="middle" flexItem />
                              <Grid sx={{ marginLeft: '20px' }} direction={'row'} container mt={4} spacing={5}>
                                <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                  <Grid xs={12} item md={6}>
                                    <FormLabel>
                                      <>Field Name</>
                                    </FormLabel>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      name="field_name_labour"
                                      {...register(`additionalLabourItem.${index}.field_name_labour`)}
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid>
                                  <Grid xs={12} item md={6}>
                                    <FormLabel>
                                      <>Field Value</>
                                    </FormLabel>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      name="field_value_labour"
                                      {...register(`additionalLabourItem.${index}.field_value_labour`)}
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid>
                                  <Divider sx={{ marginTop: '15px' }} />
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                        <Grid>
                          <Button
                            variant="contained"
                            sx={{ marginTop: '25px' }}
                            onClick={handleAdditionalLabourButton}
                            startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                          >
                            Add Additional Fields
                          </Button>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={valueBiling} index={1}>
                      <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
                        Equipment and Materials
                      </Typography>
                      {material.map((item, i) => (
                        <>
                          <Grid key={i}>
                            <Grid sx={{ display: 'flex' }}>
                              <Grid sx={{ marginRight: '10px' }}>
                                <CancelIcon
                                  key={i}
                                  onClick={() => handleMaterialDelete(i)}
                                  sx={{ fontSize: '42px', color: 'red' }}
                                  startIcon={<Iconify icon="eva:plus-fill" />}
                                >
                                  {''}
                                </CancelIcon>
                              </Grid>
                              <Divider orientation="vertical" variant="middle" flexItem />
                              <Grid sx={{ marginLeft: '20px' }} direction={'row'} container mt={4} spacing={5}>
                                <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                  <Grid xs={12} md={6} item>
                                    <FormLabel>
                                      <>
                                        Cost Item<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <ChipDropdown
                                      state={`materialItem.${i}.costItem`}
                                      setValue={setValue}
                                      getValues={getValues}
                                      data={initialData.materialCostItem}
                                      startIcon={<FmdGoodIcon />}
                                      register={register}
                                      validation={true}
                                      errors={errors}
                                    />
                                  </Grid>
                                  {/* <Grid xs={12} md={6} item>
                                    <FormLabel>
                                      <>
                                        Name<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type="text"
                                      {...register(`materialItem.${i}.name`, {
                                        required: '*Field is required and needs to be a text',
                                      })}
                                      error={Boolean(errors.materialItem ? errors.materialItem[i].name : null)}
                                      helperText={
                                        Boolean(errors.materialItem ? errors.materialItem[i].name : false)
                                          ? '*Field is required and needs to be a text'
                                          : null
                                      }
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid> */}
                                  <Grid xs={12} md={6} item>
                                    <FormLabel>
                                      <>
                                        Unit<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type="text"
                                      {...register(`materialItem.${i}.unit`, {
                                        required: '*Field is required and needs to be a text',
                                      })}
                                      error={Boolean(errors.materialItem ? errors.materialItem[i].unit : null)}
                                      helperText={
                                        Boolean(errors.materialItem ? errors.materialItem[i].unit : false)
                                          ? '*Field is required and needs to be a text'
                                          : null
                                      }
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid>
                                  <Grid xs={12} md={6} item>
                                    <FormLabel>
                                      <>
                                        Unit Cost<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type="number"
                                      {...register(`materialItem.${i}.unitCost`, {
                                        required: '*Field is required and needs to be a text',
                                      })}
                                      error={Boolean(errors.materialItem ? errors.materialItem[i].unitCost : null)}
                                      helperText={
                                        Boolean(errors.materialItem ? errors.materialItem[i].unitCost : false)
                                          ? '*Field is required and needs to be a text'
                                          : null
                                      }
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid mt={2} container>
                                  <Grid item>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            defaultChecked={
                                              params && getValues(`materialItem.${i}.text_exemptLabour`) === true
                                                ? true
                                                : false
                                            }
                                            checked={getValues(`materialItem.${i}.text_exemptLabour`) ? true : false}
                                            onChange={(e, val) => {
                                              setValue(`materialItem.${i}.text_exemptLabour`, val);
                                            }}
                                          />
                                        }
                                        label="Text Exempt"
                                      />
                                    </FormGroup>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
                        </>
                      ))}
                      <Grid mt={2}>
                        <Button
                          variant="contained"
                          onClick={handleAddMaterial}
                          startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                        >
                          Add Equipment and Materials Item
                        </Button>
                      </Grid>
                      <Grid mt={3}>
                        <Grid>
                          <Typography variant="h6"> Additional Fields</Typography>
                        </Grid>
                        {additionalEquipment.map((element, index) => (
                          <>
                            <Grid sx={{ display: 'flex', marginTop: '10px' }}>
                              <Grid sx={{ marginRight: '15px' }}>
                                <HighlightOffIcon
                                  variant="contained"
                                  onClick={() => handleAdditionalEquipmentDelete(index)}
                                  sx={{ fontSize: '42px', color: 'red', marginTop: '20px' }}
                                  startIcon={<Iconify icon="eva:plus-fill" />}
                                >
                                  {''}
                                </HighlightOffIcon>
                              </Grid>
                              <Divider orientation="vertical" variant="middle" flexItem />
                              <Grid sx={{ marginLeft: '20px' }} direction={'row'} container mt={4} spacing={5}>
                                <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                  <Grid xs={12} item md={6}>
                                    <FormLabel>
                                      <>Field Name</>
                                    </FormLabel>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      name="field_name_labour"
                                      {...register(`additionalEquipmentItem.${index}.field_name_equipment`)}
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid>
                                  <Grid xs={12} item md={6}>
                                    <FormLabel>
                                      <>Field Value</>
                                    </FormLabel>
                                    <TextField
                                      fullWidth
                                      type="text"
                                      name="field_value_labour"
                                      {...register(`additionalEquipmentItem.${index}.field_value_equipment`)}
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid>
                                  <Divider sx={{ marginTop: '15px' }} />
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ))}
                        <Grid>
                          <Button
                            variant="contained"
                            sx={{ marginTop: '25px' }}
                            onClick={handleAdditionalEquipmentButton}
                            startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                          >
                            Add Additional Fields
                          </Button>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={valueBiling} index={2}>
                      <Typography style={{ marginBottom: 30 }} variant="h6" component="h2">
                        Fixed Price
                      </Typography>
                      {fix.map((item, i) => (
                        <>
                          <Grid key={i}>
                            <Grid sx={{ display: 'flex' }}>
                              <Grid sx={{ marginRight: '10px' }}>
                                <CancelIcon
                                  key={i}
                                  onClick={() => handleFixDelete(i)}
                                  sx={{ fontSize: '42px', color: 'red' }}
                                  startIcon={<Iconify icon="eva:plus-fill" />}
                                >
                                  {''}
                                </CancelIcon>
                              </Grid>
                              <Divider orientation="vertical" variant="middle" flexItem />
                              <Grid sx={{ marginLeft: '20px' }} direction={'row'} container mt={4} spacing={5}>
                                <Grid container direction={'row'} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                                  <Grid xs={12} item md={6}>
                                    <FormLabel>
                                      <>
                                        Cost Item<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <ChipDropdown
                                      state={`fixItem.${i}.costItem`}
                                      setValue={setValue}
                                      getValues={getValues}
                                      data={initialData.fixedCostItem}
                                      startIcon={<FmdGoodIcon />}
                                      register={register}
                                      validation={true}
                                      errors={errors}
                                    />
                                  </Grid>
                                  {/* <Grid xs={12} item md={6}>
                                    <FormLabel>
                                      <>
                                        Name<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type="text"
                                      {...register(`fixItem.${i}.name`, {
                                        required: '*Field is required and needs to be a text',
                                      })}
                                      error={Boolean(errors.fixItem ? errors.fixItem[i].name : null)}
                                      helperText={
                                        Boolean(errors.fixItem ? errors.fixItem[i].name : false)
                                          ? '*Field is required and needs to be a text'
                                          : null
                                      }
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid> */}
                                  <Grid xs={12} item md={6}>
                                    <FormLabel>
                                      <>
                                        Unit<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type="text"
                                      {...register(`fixItem.${i}.unit`, {
                                        required: '*Field is required and needs to be a text',
                                      })}
                                      error={Boolean(errors.fixItem ? errors.fixItem[i].unit : null)}
                                      helperText={
                                        Boolean(errors.fixItem ? errors.fixItem[i].unit : false)
                                          ? '*Field is required and needs to be a text'
                                          : null
                                      }
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid>
                                  <Grid xs={12} item md={6}>
                                    <FormLabel>
                                      <>
                                        Unit Cost<span style={{ color: 'red' }}>*</span>
                                      </>
                                    </FormLabel>
                                    <TextField
                                      sx={{ width: '100%' }}
                                      type="number"
                                      {...register(`fixItem.${i}.unitCost`, {
                                        required: '*Field is required and needs to be a text',
                                      })}
                                      error={Boolean(errors.fixItem ? errors.fixItem[i].unitCost : null)}
                                      helperText={
                                        Boolean(errors.fixItem ? errors.fixItem[i].unitCost : false)
                                          ? '*Field is required and needs to be a text'
                                          : null
                                      }
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 50 }}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid mt={2} container>
                                  <Grid item>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            defaultChecked={
                                              params && getValues(`fixItem.${i}.text_exemptLabour`) === true
                                                ? true
                                                : false
                                            }
                                            checked={getValues(`fixItem.${i}.text_exemptLabour`) ? true : false}
                                            onChange={(e, val) => {
                                              setValue(`fixItem.${i}.text_exemptLabour`, val);
                                            }}
                                          />
                                        }
                                        label="Text Exempt"
                                      />
                                    </FormGroup>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider style={{ marginBlock: 20 }} orientation="horizontal" variant="middle" flexItem />
                        </>
                      ))}
                      <Grid mt={2}>
                        <Button
                          variant="contained"
                          onClick={handleAddFix}
                          startIcon={<AddIcon sx={{ fontSize: '42px' }} />}
                        >
                          Add Fixed Price Item
                        </Button>
                      </Grid>
                    </TabPanel>
                  </Card>
                </Grid>
              </Grid>

              <Grid mt={3}>
                <Grid sx={{ display: 'flex' }}>
                  <Divider orientation="vertical" variant="" flexItem />
                  <Grid sx={{ marginLeft: '20px' }} direction={'row'} container spacing={5}>
                    <Grid container direction={'row'} mt={4} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                      <Grid xs={12} item md={6}>
                        <FormLabel>
                          <>Total Cost Hour</>
                        </FormLabel>
                        <TextField
                          fullWidth
                          type="number"
                          step="0.01"
                          min={0}
                          value={TotalCostHour}
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(errors ? errors.total_costhour : null)}
                          helperText={errors ? errors.total_costhour?.message : null}
                        />
                      </Grid>
                      <Grid md={6} item xs={12}>
                        <FormLabel>
                          <>Total Estimated Hour</>
                        </FormLabel>
                        <TextField
                          fullWidth
                          type="number"
                          step="0.01"
                          name="Total Estimated Hour"
                          value={totalEstimatedHour}
                          error={Boolean(errors ? errors.total_estimated_hour : null)}
                          helperText={errors ? errors.total_estimated_hour?.message : null}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ marginBlock: 30 }} orientation="horizontal" variant="middle" flexItem />

              <Grid container direction={'row'} mt={2} rowSpacing={2} spacing={{ xs: 0, sm: 0, md: 5 }}>
                {/* Remarks */}
                <Grid item xs={12} md={6}>
                  <FormLabel>
                    <>Remarks</>
                  </FormLabel>
                  <TextField
                    multiline
                    rows={3}
                    fullWidth
                    onChange={(e, val) => {
                      settextAreaCount(e.target.value.length);
                    }}
                    {...register('remark')}
                    inputProps={{ maxLength: 300 }}
                    InputLabelProps={{ shrink: true }}
                  />
                  <Grid sx={{ fontSize: '12px', textAlign: 'end' }}> {textAreaCount} / 300</Grid>
                </Grid>
                {/* Attachments */}
                <Grid item xs={12} md={6}>
                  <Grid container mt={2}>
                    <Grid item xs={12} md={12}>
                      <Typography variant="h6" component="h2">
                        Attachment
                      </Typography>
                    </Grid>
                    <Grid sx={{ marginTop: '10px' }} md={12} xs={12}>
                      {attachments !== '' ? (
                        <Grid sx={{ display: 'flex', flexWrap: 'wrap' }}>
                          <Grid item md={4} xs={12}>
                            <Button variant="contained" component="label" onClick={() => handleviewimage(attachments)}>
                              view image
                            </Button>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Button variant="contained" component="label">
                              <a
                                href={attachments}
                                download
                                rel="noreferrer"
                                target={'_blank'}
                                style={{ textDecoration: 'none', color: 'white' }}
                                label="download"
                              >
                                {'download'}
                              </a>
                            </Button>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <Button variant="contained" component="label" onClick={() => handleDeleteImage()}>
                              Delete
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        <>
                          <Button sx={{ padding: '6px 40px' }} variant="contained" component="label">
                            select file
                            <input hidden accept="image/*" multiple type="file" onChange={(e) => handleFile(e)} />
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <FormGroup>
          <FormControlLabel
            control={
              <>
                <Checkbox
                  defaultChecked={params && getValues('active') ? true : false}
                  checked={getValues('active') ? true : false}
                  onChange={(e, val) => {
                    setValue('active', val);
                    setcheckbox(val);
                  }}
                />
              </>
            }
            label="Active"
          />
        </FormGroup>
        <Grid sx={{ display: 'flex' }}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            style={{ width: 300 }}
          >
            Submit
          </LoadingButton>
          {params && (
            <Button
              variant="contained"
              size="large"
              color="error"
              style={{ width: 300, marginLeft: '20px' }}
              onClick={() => {
                navigate('/task/list');
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
      </Stack>
    </form>
  );
}

TaskCreateForm.propTypes = {
  register: PropTypes.any,
  params: PropTypes.any,
  errors: PropTypes.object,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.func,
  control: PropTypes.any,
  value: PropTypes.any,
  handleChange: PropTypes.func,
};
